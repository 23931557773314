import { useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from 'formik';
import axios from 'axios';
import { url_api } from '../../config';
import { ToastAlert } from "../../components";

const ModalSelesaiMendonor = ({data, show, onClose = () => {} }) => {
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const dataDefault = useMemo(() => {
        if(!data) return {}

        return {
            namaPendonor: data.nama,
            idPengajuan: data.idPengajuan,
            idPermintaan: data.idPermintaan
        }
    }, [data])

    const _selesaiMendonor = async (value) => {
        try{
            const res = await axios.put(`${url_api}/mendonor/done-pengajuan/${dataDefault.idPengajuan}`, {
                jumlahKantong: value.kantong_darah,
                namaPendonor: dataDefault.namaPendonor
            }, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            if(res.data.success){
                setShowToast(true)
                setLoading(false)
                setMessage(res.data.message)
                setSuccess(res.data.success)

                setTimeout(() => {
                    window.location.href = `/detail-permintaan/${dataDefault.idPermintaan}`;
                }, 1500)
            }
        }catch(err){
            setShowToast(true)
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>
            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Modal show={show} onHide={onClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Selesaikan Pendonoran</Modal.Title>
                </Modal.Header>

                <Formik
                    initialValues={{
                        kantong_darah: ''
                    }}
                    onSubmit={(values) => {
                        setTimeout(() => {
                            _selesaiMendonor(values)
                            setLoading(false)
                        }, 1000)

                        setLoading(true)
                    }}
                >

                    {({values, handleSubmit, handleChange}) => (

                        <form method='post' onSubmit={handleSubmit}>
                            <Modal.Body>
                                <div align="center">Apakah <b>{dataDefault.namaPendonor}</b> sudah melakukan pendonoran darah?</div>
                                <div align="center">Jika sudah, silahkan Anda klik tombol <b>Ya</b></div>

                                <div className="mt-3 mb-2">
                                    <label htmlFor="kantong_darah" className="mb-1">Jumlah Kantong Darah Yang Didonorkan</label>
                                    <input 
                                        type="number"
                                        name="kantong_darah"
                                        id="kantong_darah"
                                        placeholder="Masukkan Jumlah Kantong"
                                        className="form-control"
                                        onChange={handleChange}
                                    />
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                {loading ?
                                <button type="button" className="btn btn-secondary btn-sm" disabled>Tidak</button>
                                :
                                <button type="button" className="btn btn-secondary btn-sm" onClick={onClose}>Tidak</button>
                                }
                                

                                {loading ?
                                <button type="button" className="btn btn-success btn-sm" disabled><Spinner size="sm"/></button>
                                :
                                <button type="submit" className="btn btn-success btn-sm">Ya</button>
                                }
                            </Modal.Footer>
                        </form>

                    )}

                </Formik>

            </Modal>
        </>
    )
}

export default ModalSelesaiMendonor;