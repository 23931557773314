import { Card, Spinner, Row, Col } from 'react-bootstrap';
import imgUser from './user.png';
import { url_api, url_static, getCurrentDate } from '../../config';
import { ToastAlert } from '../../components';
import { useState } from 'react';
import axios from 'axios';
import moment from 'moment';

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'blue',
    padding: '20px'
}

const CardPencari = ({dataUser, dataPencari}) => {
    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const _sendPengajuan = async (data) => {
        try{
            const res = await axios.post(`${url_api}/mendonor/send-pengajuan`, {
                alamatPendonor: data.alamatPendonor,
                permintaanMendonorId: data.permintaanMendonorId,
                pencariId: data.pencariId,
                pendonorId: data.pendonorId,
                fullnamePendonor: data.fullnamePendonor,
                noHpPendonor: data.noHpPendonor,
                emailPendonor: data.emailPendonor,
                tglPengajuan: getCurrentDate()
            }, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            if(res.data.success){
                setShowToast(true)
                setMessage(res.data.message)
                setSuccess(res.data.success)
                setLoading(false)
            }
        }catch(err){
            setShowToast(true)
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>
            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Card style={cardStyle}>
                <center>
                    {dataPencari.pencari.foto === '' ?
                    <img src={imgUser} className="img-fluid" alt="Img User" style={{height: '100px', borderRadius: '50%'}} />
                    :
                    <img src={`${url_static}/img-pencari/${dataPencari.pencari.foto}`} className="img-fluid" alt="Img User" style={{height: '100px', borderRadius: '50%'}} />
                    }
                </center>

                <h4 className="mt-3 text-center">{dataPencari.fullname_pencari}</h4>
                <h5 className="text-center">{dataPencari.pencari.alamat === '' ? 'Belum memiliki alamat' : dataPencari.pencari.alamat}</h5><hr/>

                <div id="data-permintaan">
                    <div>Tanggal Permintaan : <b>{moment(dataPencari.tgl_permintaan).format("DD/MM/YYYY")}</b></div>
                    <div>Tanggal Mulai Mendonor : <b>{moment(dataPencari.tgl_mendonor).format("DD/MM/YYYY")}</b></div><hr/>

                    <h5>Keterangan</h5>
                    <div>{dataPencari.keterangan}</div>

                    <Row>
                        <Col md={12}>
                            {loading ?
                            <button className="mt-3 btn btn-primary w-100" disabled>Loading... <Spinner size="sm" /> </button>
                            :

                            <button className="mt-3 btn btn-primary w-100" onClick={() => {
                                setTimeout(() => {
                                    _sendPengajuan({
                                        alamatPendonor: dataUser.detailUser.alamat,
                                        permintaanMendonorId: dataPencari.id,
                                        pencariId: dataPencari.pencariId,
                                        pendonorId: dataUser.detailUser.id,
                                        fullnamePendonor: dataUser.user.firstname + " " + dataUser.user.lastname,
                                        noHpPendonor: dataUser.user.no_hp,
                                        emailPendonor: dataUser.user.email
                                    })
                                }, 1000)

                                setLoading(true)
                            }}>Bantu Sekarang</button>
                            }

                        </Col>
                    </Row>
                </div>
            </Card>

        </>
    )
}

export default CardPencari;