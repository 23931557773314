import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import { useClient } from "./client"

export const MenuPMI = ({data}) => {
    const client = useClient()

    return(
        <>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="/pmi">
                        <div>Dashboard</div>
                    </Navbar.Brand>

                    <Navbar.Toggle />

                    <Navbar.Collapse id="basic-navbar-nav">
                        {/* <Nav>
                            <Nav.Link href={`/pengajuan-mendonor`}>Pengajuan Mendonor</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href={`/permintaan-mendonor`}>Data Permintaan</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href={`/data-mendonor`}>Data Mendonor</Nav.Link>
                        </Nav> */}
                    </Navbar.Collapse>

                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <NavDropdown title={`${data.user.firstname} ${data.user.lastname}`} id="basic-nav-dropdown">
                                <NavDropdown.Item href="#" onClick={() => client.logout()}><FontAwesomeIcon icon={faSignOut} /> Keluar</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}