import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { MenuPencari } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { url_api } from "../../config";
// import { useLocation } from "react-router-dom";
import moment from "moment";
import CardPendonor from "./CardPendonor";
import { useHistory } from "react-router-dom";
import ModalDelete from "./ModalDelete";

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'red',
    padding: '20px'
}

const Layout = ({data}) => {
    // const search = useLocation().search
    // const searchParams = new URLSearchParams(search)
    // parseInt(searchParams.get('yourId'))
    const [permintaan, setPermintaan] = useState([])
    const [countPermintaan, setCountPermintaan] = useState([])
    const [pengajuan, setPengajuan] = useState([])

    const history = useHistory()

    const [showModal, setShowModal] = useState(false)
    const _handleShow = () => setShowModal(true)
    const _handleClose = () => setShowModal(false)

    const _fetchRequest = async () => {
        try{
            const res = await axios.get(`${url_api}/mendonor/get-request/${data.detailUser.id}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPermintaan(res.data.result[0])
            setCountPermintaan(res.data.result.length)

            if(res.data.result.length > 0){ // jika ada data permintaan mendonor
                // set id permintaan mendonor
                localStorage.setItem('idPermintaan', res.data.result[0].id)
            }
        }catch(err){
            console.log(err)
        }
    }

    const _fetchPengajuan = async (idPermintaan) => {
        try{
            const res = await axios.get(`${url_api}/mendonor/get-pengajuan/${data.detailUser.id}/${idPermintaan}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPengajuan(res.data.result)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchRequest()
        _fetchPengajuan(localStorage.getItem('idPermintaan'))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, localStorage.getItem('idPermintaan')])

    return(
        <>
            <MenuPencari data={data}/>

            <ModalDelete 
                show={showModal} 
                onClose={_handleClose}
                data={{ idPermintaan: localStorage.getItem('idPermintaan') }}
            />

            <Container className="mt-5">

                {countPermintaan > 0 ? // jika ada data permintaan mendonor

                <>
                    
                    {/* {moment().format("DD/MM/YYYY") >= moment(permintaan.tgl_permintaan).format("DD/MM/YYYY") ?
                    
                    // jika tgl permintaan sudah lewat, maka akan muncul pesan untuk hapus permintaan
                    <div className="alert alert-danger">Permintaan Donor ini sudah lewat, silahkan dihapus lalu buat permintaan yang baru</div>

                    : null} */}

                    <Row>
                        <Col md={5}>
                            <Card style={cardStyle}>
                                <h4>Data Permintaan Mendonor</h4>
                                <div>Dibawah ini adalah data detail mengenai permintaan Anda ke Pendonor</div><hr/>

                                {permintaan === undefined ? null
                                :
                                <>
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td>Tanggal Permintaan</td>
                                                <td>:</td>
                                                <td>{moment(permintaan.tgl_permintaan).format("DD/MM/YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Mulai Mendonor</td>
                                                <td>:</td>
                                                <td>{moment(permintaan.tgl_mendonor).format("DD/MM/YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td>Kantong Darah</td>
                                                <td>:</td>
                                                <td><b>{permintaan.kantong_darah}</b></td>
                                            </tr>
                                        </tbody>
                                    </table><hr/>

                                    <h5>Golongan Darah Yang Dicari : <Badge bg="primary">{permintaan.gol_darah === 'APLUS' ? 'A+' : permintaan.gol_darah === 'ABPLUS' ? 'AB+' : permintaan.gol_darah === 'OPLUS' ? 'O+' : permintaan.gol_darah}</Badge></h5><hr/>

                                    <h5>Keterangan</h5>
                                    <div>{permintaan.keterangan}</div><hr/>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <button className="btn btn-danger w-100" onClick={_handleShow}>Hapus Permintaan</button>
                                        </div>
                                    </div>
                                </>
                                }
                            </Card>
                        </Col>

                        {/* Data Pengajuan Pendonor */}
                        <Col md={7}>
                            <h4>Pendonor Yang Ingin Membantu</h4>
                            <div>Dibawah ini adalah pendonor yang ingin membantu Anda mendonorkan darah mereka. Silahkan dipilih dengan cara klik tombol <b>Terima</b>. Pilih salah satu</div><hr/>

                            <div className='alert alert-info'>Kontak Pendonor akan di dapat ketika Anda menerimanya untuk mendonorkan darah.</div>

                            {pengajuan === undefined ? null
                            :
                            
                            pengajuan.length > 0 ? // jika sudah ada pengajuan

                            <Row className="mt-4">
                                {pengajuan.map((val, key) => {
                                    return (
                                        <Col md={6} key={key}>
                                            <CardPendonor 
                                                permintaanMendonorId={val.permintaanMendonorId}
                                                pendonorId={val.pendonorId}
                                                fullname={val.fullname_pendonor}
                                                fotoProfil={val.pendonor.foto}
                                                alamat={val.pendonor.alamat}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>

                            : // jika belum ada pengajuan
                            
                            <h5>Belum ada pendonor. <a href="/pengajuan-mendonor">Silahkan Refresh</a></h5>

                            }
                        </Col>
                    </Row>
                </>

                :

                <>
                    <h2 align="center">Belum ada Permintaan Mendonor</h2>
                    <h5 align="center">Anda belum membuat permintaan mendonor ke setiap Pendonor</h5>

                    <Row className="justify-content-md-center mt-4">
                        <Col md={2}>
                            <button className="btn btn-danger w-100" onClick={() => {
                                history.push('/pencari')
                            }}>Kirim Permintaan</button>
                        </Col>
                    </Row>
                </>

                }

            </Container><br/>
        </>
    )
}

export default Layout;