import { Switch, Route } from "react-router-dom";

import {PrivateRoute} from './components/PrivateRoute'
import Root from './pages/Root'

import Homepage from "./pages/Homepage";
import RegisterPendonor from './pages/RegisterPendonor'
import RegisterPencari from "./pages/RegisterPencari";
import LoginAdmin from "./pages/Admin.Login";
import LoginPMI from "./pages/Login.PMI";
import LoginPendonor from "./pages/Login.Pendonor";
import LoginPencari from "./pages/Login.Pencari";

const Router = () => {
    return(
        <Switch>
            <Route path='/' exact component={Homepage} />
            <Route path='/login-pencari' component={LoginPencari} />
            <Route path='/login-pendonor' component={LoginPendonor} />
            <Route path='/login-admin' component={LoginAdmin} />
            <Route path='/login-pmi' component={LoginPMI} />
            <Route path='/register/pendonor' component={RegisterPendonor} />
            <Route path='/register/pencari' component={RegisterPencari} />
            <PrivateRoute path="/" component={Root} />
        </Switch>
    )
}

export default Router;