import Helmet from 'react-helmet'
import Layout from './Layout';
import { useState, useEffect, useMemo } from 'react';
import { useClient } from '../../components';

const DetailPermintaanBankDarah = () => {
    const client = useClient()
    const [user, setUser] = useState([])

    useEffect(() => {
        setUser(client.account)
    }, [client])

    const defaultData = useMemo(() => {
        if(!user) return {}

        return {
            user
        }
    }, [user])

    return(
        <>
            <Helmet>
                <title>Detail Permintaan</title>
            </Helmet>

            <Layout data={defaultData} />
        </>
    )
}

export default DetailPermintaanBankDarah;