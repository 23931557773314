import { Card, Col, Container, Row, Tab, Tabs, Modal, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { MenuAdmin } from "../../components";
import axios from "axios";
import { url_api, url_static } from "../../config";
import { useEffect, useState } from "react";
import ImgUser from './user.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import Maps from "./Maps";
import moment from "moment";

const cardStyle = {
    borderColor: 'red',
    borderRadius: '20px',
    padding: '20px'
}

const Layout = ({user}) => {
    const { idUser } = useParams()
    const [pendonor, setPendonor] = useState([])
    const [detailPendonor, setDetailPendonor] = useState([])
    const [key, setKey] = useState('informasi');

    const [showModal, setShowModal] = useState(false)
    const _handleClose = () => setShowModal(false)
    const _handleShow = () => setShowModal(true)

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState()

    const _deletePendonor = async () => {
        try{
            const res = await axios.delete(`${url_api}/pendonor/${idUser}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })
    
            if(res.data.success){
                setSuccess(res.data.success)
                setMessage(res.data.message)
                setLoading(false)
    
                setTimeout(() => {
                    window.location.href = "/manage-pendonor";
                }, 2000)
            }
        }catch(err){
            console.log(err)
        }
    }

    const _fetchData = async () => {
        try{
            const res = await axios.get(`${url_api}/pendonor/${idUser}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPendonor(res.data.pendonor[0])
            setDetailPendonor(res.data.pendonor[0].pendonors[0])
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <>
            <MenuAdmin user={user} />

            <Container className="mt-5">
                <Row className="justify-content-md-center">
                    <Col md={7}>
                        <Card style={cardStyle}>
                            <center>
                                {detailPendonor.foto === '' ?
                                <img src={ImgUser} className="img-fluid" alt="Img User" style={{height: '150px', borderRadius: '50%'}} />
                                :
                                <img src={`${url_static}/img-pendonor/${detailPendonor.foto}`} className="img-fluid" alt="Img User" style={{height: '180px', borderRadius: '50%'}} />
                                }
                            </center>
                            <h4 className="text-center mt-3">{pendonor.firstname} {pendonor.lastname}</h4>
                            <h6 className="text-center">+62 {pendonor.no_hp} {pendonor.verified_nohp === 'no' ? <a href="#s" style={{color: 'red'}} title="Belum Terverifikasi"><FontAwesomeIcon icon={faTimes} /></a> : <a href="#s" style={{color: 'green'}} title="Terverifikasi"><FontAwesomeIcon icon={faCheckCircle} /></a>}</h6><hr/>

                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                            >
                                {/* Informasi Pribadi */}
                                <Tab eventKey="informasi" title="Informasi Pribadi">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td>Email</td>
                                                <td>:</td>
                                                <td>{pendonor.email} {pendonor.verified_email === 'no' ? <a href="#s" style={{color: 'red'}} title="Belum Terverifikasi"><FontAwesomeIcon icon={faTimes} /></a> : <a href="#s" style={{color: 'green'}} title="Terverifikasi"><FontAwesomeIcon icon={faCheckCircle} /></a>}</td>
                                            </tr>
                                            <tr>
                                                <td>Nomor ID</td>
                                                <td>:</td>
                                                <td>{detailPendonor.nomor_id === '' ? 'Belum memiliki Nomor ID' : detailPendonor.nomor_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Tempat Lahir</td>
                                                <td>:</td>
                                                <td>{detailPendonor.tempat_lahir === '' ? '-' : detailPendonor.tempat_lahir}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Lahir</td>
                                                <td>:</td>
                                                <td>{detailPendonor.tgl_lahir === '0000-00-00' ? '-' : moment(detailPendonor.tgl_lahir).format("DD/MM/YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td>Jenis Kelamin</td>
                                                <td>:</td>
                                                <td>{detailPendonor.jenis_kelamin}</td>
                                            </tr>
                                            <tr>
                                                <td>Golongan Darah</td>
                                                <td>:</td>
                                                <td>
                                                    { detailPendonor.gol_darah === 'APLUS' ? 'A+' : detailPendonor.gol_darah === 'ABPLUS' ? 'AB+' : detailPendonor.gol_darah === 'OPLUS' ? 'O+'
                                                    : detailPendonor.gol_darah }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table><hr/>

                                    <Card bg="danger" text="white">
                                        <Card.Body>
                                            <h4><FontAwesomeIcon icon={faWarning} /> Danger Area</h4><hr/>
                                            <h6>Hapus Akun</h6>
                                            <div>Hapus akun ini jika tidak digunakan lagi atau nomor telefon belum diverifikasi</div>

                                            <a href="#s" className="btn btn-outline-light mt-3" onClick={() => _handleShow()}>Hapus Akun</a>
                                        </Card.Body>
                                    </Card>
                                </Tab>

                                {/* Alamat */}
                                <Tab eventKey="alamat" title="Alamat">
                                    <Maps 
                                        latitude={detailPendonor.latitude} 
                                        longtitude={detailPendonor.longtitude} 
                                        alamat={detailPendonor.alamat}
                                    />
                                </Tab>
                            </Tabs>
                        </Card>
                    </Col>
                </Row><br/><br/>
            </Container>

            <Modal show={showModal} onHide={_handleClose} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        {success ?
                        <div className="alert alert-success">{message}</div>
                        : null}

                        Yakin ingin hapus Pendonor <b>{pendonor.firstname}</b> ?
                    </Modal.Body>
                <Modal.Footer>
                    {loading ?
                    <button className="btn btn-secondary" disabled>Tidak</button>
                    :
                    <button className="btn btn-secondary" onClick={_handleClose}>Tidak</button>
                    }

                    {loading ? 
                    <button className="btn btn-danger" disabled><Spinner size="sm"/></button>
                    :
                    <button className="btn btn-danger" onClick={() => {
                        setTimeout(() => {
                            _deletePendonor()
                        }, 500)
                        
                        setLoading(true)
                    }}>
                        Ya
                    </button>
                    }
                    
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Layout;