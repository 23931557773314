import {Container, Row, Col, Table} from 'react-bootstrap'
import Header from './Header';
import imgWelcome from './images/donate-blood.jpg';
import { useEffect, useState } from 'react';
import AOS from 'aos';
import Maps from './Maps';
import {Footer} from '../../components';
import axios from 'axios';
import { url_api } from '../../config';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

const Layout = () => {
    const [permintaan, setPermintaan] = useState([])
    const [limit, setLimit] = useState(0)
    const [page, setPage] = useState(0)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [message, setMessage] = useState("")

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const _fetchPermintaanMendonor = async () => {
        try{
            const res = await axios.get(`${url_api}/permintaan/list-all?page=${page}&limit=${limit}`)

            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
            setPermintaan(res.data.result)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        AOS.init()

        _fetchPermintaanMendonor()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit])

    return(
        <>
            <Header/>

            <div id="welcoming" className='mt-5'>
                <Container data-aos="fade-up" data-aos-duration="1000">
                    <Row>
                        <Col md={6}>
                            <img src={imgWelcome} alt="Welcome" className='img-fluid img-welcome' style={{height: '450px', boxShadow: '5px 0 5px -3px #bcbcbc', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'}} />
                        <br/><br/></Col>
                        <Col md={6} className="content-right">
                            <h1>Temukan Pendonor Sukarela dengan lebih mudah dan efektif</h1>
                            <p><font color="red">Pendonor Sukarela</font> adalah tempat untuk mempertemukan pendonor sukarela dengan mereka yang membutuhkan darah</p><br/>

                            <Row>
                                <Col md={6}>
                                    <a href='#find-pendonor' className='btn-foundblood'>Temukan Pendonor</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <div id='permintaan-mendonor' className='mt-5'>
                <Container data-aos="zoom-in" data-aos-duration="1500">
                    <h3>Permintaan Mendonor</h3>
                    <div align="center">Semua data permintaan mendonor</div><hr/>
                    
                    <div className='alert alert-primary'>
                        <li>Permintaan yang berwarna hijau, itu tandanya sudah selesai</li>
                    </div>

                    <Row>
                        <Col md={2}>
                            <select
                                name="limit"
                                id="limit"
                                className="form-select"
                                onChange={(e) => {
                                    setLimit(e.target.value)
                                }}
                            >
                                <option value="">Limit Data</option>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={150}>150</option>
                                <option value={200}>200</option>
                            </select>
                        </Col>
                    </Row>

                    <Table className='mt-2' responsive={true} striped bordered>
                        <thead>
                            <tr>
                                <th>Nama Pencari</th>
                                <th>Tgl Permintaan</th>
                                <th>Tgl Mendonor</th>
                                <th>Gol Darah</th>
                                <th>Kantong Darah</th>
                                <th colSpan={2}>Keterangan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {permintaan.length > 0 ?
                            permintaan.map((val, key) => {
                                return (
                                    <>
                                        <tr key={key} style={{backgroundColor: val.status_done === "yes" ? "#8fff5f" : null }}>
                                            <td>{val.fullname_pencari}</td>
                                            <td>{moment(val.tgl_permintaan).format('DD/MM/YYYY')}</td>
                                            <td>{moment(val.tgl_mendonor).format('DD/MM/YYYY')}</td>
                                            <td>
                                                { val.gol_darah === 'APLUS' ? 'A+' : val.gol_darah === 'ABPLUS' ? 'AB+' : val.gol_darah === 'OPLUS' ? 'O+'
                                                : val.gol_darah }
                                            </td>
                                            <td>{val.kantong_darah !== 0 ? `dibutuhkan ${val.kantong_darah} kantong` : 'terpenuhi'}</td>
                                            <td>{val.keterangan}</td>
                                            <td>
                                                {sessionStorage.getItem('token') !== null ?
                                                <>
                                                    {sessionStorage.getItem('role') === "pendonor" && val.status_done === "no" ? <a href={`/detail-permintaan?id=${val.id}`} className='btn btn-outline-danger'>Bantu</a> : 'kamu sedang login sebagai pencari'}
                                                </>
                                                :
                                                <>
                                                    {val.status_done === "no" ? <a href={`/login-pendonor?permintaan=yes&id=${val.id}`} className='btn btn-outline-danger'>Bantu</a> : null}
                                                </>
                                                }
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={8} align="center">Belum ada data</td>
                            </tr>
                            }
                        </tbody>
                    </Table>

                    <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                    <div><font color="red">{message}</font></div>

                    {permintaan.length > 0 ? 
                    <nav key={rows} style={{marginTop: '1%'}}>
                        <ReactPaginate
                            previousLabel={"< Previous"}
                            nextLabel={"Next >"}
                            pageCount={Math.min(10, pages)}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeLinkClassName={"page-item active"}
                            disabledLinkClassName={"page-item disabled"}
                        />
                    </nav>
                    : null}

                </Container>
            </div>

            <div id='about' className='mb-5' style={{marginTop: '100px'}}>
                <Container data-aos="zoom-in" data-aos-duration="1500">
                    <h3>Tentang Kami</h3>
                    <p>
                        <font style={{color: '#ff2323'}}>pendonorsukarela</font> adalah platform yang mempermudah para pencari kebutuhan darah untuk menemukan pendonor darah sukarela. 
                        Tujuan kami membangun <font style={{color: '#ff2323'}}>pendonorsukarela</font> karena kami melihat sebuah masalah dimana banyak para pencari kebutuhan darah sulit untuk menemukan pendonor darah jika stok darah di PMI habis. 
                        Alternatif yang pencari kebutuhan darah lakukan adalah dengan melakukan broadcast ke sosial media, tapi ini tidak efektif, karena sering tidak dapat pendonor dan bahkan lama,
                        padahal darah sudah sangat dibutuhkan. Dengan munculnya platform kami, maka itu semua terselesaikan
                    </p>
                    
                    <center><hr style={{borderColor: '#ff2323', width: '8%'}} /></center>

                    <div id='benefit' className='mt-4'>
                        <h4>Mengapa Menggunakan Platform Kami</h4><br/>

                        <Row>
                            <Col md={4}>
                                <div className='card'>
                                    <h4 align="center">Cepat Menemukan Pendonor</h4>
                                    <p align="center">Anda dapat dengan cepat menemukan pendonor</p>
                                </div>
                            <br/></Col>
                            <Col md={4}>
                                <div className='card'>
                                    <h4 align="center">Gratis</h4>
                                    <p align="center">Tentunya platform kami tersedia untuk Anda secara gratis.</p>
                                </div>
                            <br/></Col>
                            <Col md={4}>
                                <div className='card'>
                                    <h4 align="center">Mudah Digunakan</h4>
                                    <p align="center">Kami membuat platform kami jadi lebih mudah untuk Anda gunakan</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <div id="join-us">
                <Container data-aos="zoom-in" data-aos-duration="1500">
                    <p className='text-center'>Setetes darah Anda dapat menyelematkan nyawa mereka yang ada diluarsana</p>
                    <h3 className='mb-4 text-center'>Ingin Jadi Pendonor Sukarela?</h3>
                    
                    <Row className='justify-content-md-center'>
                        <Col md={3}>
                            <a href='/register/pendonor' className='btn btn-outline-light w-100'>Daftar Sekarang</a>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id='find-pendonor' className='mt-5 mb-5'>
                <Container data-aos="fade-right" data-aos-duration="1500">
                    <h2>Temukan Pendonor</h2>
                    <p>Telusuri pendonor sukarela yang berada pada lokasi terdekat Anda</p>

                    <div id='maps'>
                        <Maps />
                    </div>

                    <div id='regis-pencari' className='mt-4'>
                        <h5 align="center"><b>Hubungi Pendonor</b></h5>
                        <div align="center" style={{marginLeft: '20%', marginRight: '20%'}}>Anda membutuhkan darah? Daftar sekarang juga sebagai pencari darah untuk menghubungi pendonor sukarela</div>

                        <Row className='justify-content-md-center mt-3'>
                            <Col md={2}>
                                <a href="/register/pencari" className='btn btn-danger w-100'>Daftar Sekarang</a>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}

export default Layout;