import { Card, Col, Container, Row } from "react-bootstrap";
import { MenuAdmin } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { url_api } from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale, faFemale, faUser } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import { 
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, 
} from "chart.js";
import { Bar } from "react-chartjs-2";

// register chart js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };
  
  const labels = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'Aktivitas Mendonor',
        data: [50, 20, 10, 30, 40, 80, 90, 60, 70, 100, 120, 110],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

const Layout = ({user}) => {
    // pendonor
    const [countLaki, setCountLaki] = useState(0);
    const [countPerempuan, setCountPerempuan] = useState(0);

    // pencari
    const [countPencari, setCountPencari] = useState(0);

    const _countPendonor = async () => {
        try{
            const res = await axios.get(`${url_api}/pendonor/count-jeniskelamin`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setCountLaki(res.data.countPendonorLaki);
            setCountPerempuan(res.data.countPendonorPerempuan);
        }catch(err){
            console.log(err)
        }
    }

    const _countPencari = async () => {
        try{
            const res = await axios.get(`${url_api}/pencari/count-data`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setCountPencari(res.data.countData);
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _countPendonor();
        _countPencari();
    }, [])

    return(
        <>
            <MenuAdmin user={user}/>
            
            <Container className="mt-5">
                <h3>Selamat datang, {user.firstname} {user.lastname}</h3>
                <p>Anda login sebagai administrator. Disini Anda bisa mengelola data yang ada dan dapat mengelola sistem ini</p><hr/>

                <Row>
                    <Col md={4}>
                        <Card bg="primary" text="light">
                            <Card.Body>
                                <h3><FontAwesomeIcon icon={faFemale} /> Pendonor Perempuan</h3>
                                <div>Total Pendonor perempuan yang terdaftar</div><hr/>

                                <h2 align="center">{countPerempuan}</h2>
                            </Card.Body>
                        </Card>
                    <br/></Col>
                    <Col md={4}>
                        <Card bg="dark" text="light">
                            <Card.Body>
                                <h3><FontAwesomeIcon icon={faMale} /> Pendonor Laki-laki</h3>
                                <div>Total Pendonor Laki-laki yang terdaftar</div><hr/>

                                <h2 align="center">{countLaki}</h2>
                            </Card.Body>
                        </Card>
                    <br/></Col>
                    <Col md={4}>
                        <Card bg="danger" text="light">
                            <Card.Body>
                                <h3><FontAwesomeIcon icon={faUser} /> Pencari Darah</h3>
                                <div>Total Pencari Darah yang terdaftar</div><hr/>

                                <h2 align="center">{countPencari}</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Card>
                    <Card.Body>
                        <h3>Laporan Data Mendonor </h3>
                        <div>Ini adalah data laporan seberapa banyak aktivitas mendonor per tahun</div><hr/>

                        <Row>
                            <Col md={3}>
                                <select
                                    name="filter_thn"
                                    id="filter_thn"
                                    className="form-select"
                                    onChange={(e) => {
                                        // setLimit(e.target.value)
                                    }}
                                >
                                    <option value="">Pilih Tahun</option>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={150}>150</option>
                                    <option value={200}>200</option>
                                </select>
                            </Col>
                        </Row>

                        <Bar options={options} data={data} />

                    </Card.Body>
                </Card>

            </Container>
        </>
    )
}

export default Layout;