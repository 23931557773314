import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { MenuPencari } from "../../components";
import { useEffect } from "react";
import axios from "axios";
import { url_api } from "../../config";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ModalDelete from "./ModalDelete";
// import ModalSelesai from "./ModalSelesai";

const cardStyle = {
    borderRadius: '15px',
    borderColor: 'blue',
    padding: '20px'
}

const Layout = ({data}) => {
    const [dataMendonor, setDataMendonor] = useState([])
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [keyword, setKeyword] = useState("")
    const [query, setQuery] = useState("")
    const [message, setMessage] = useState("")

    // modal delete
    const [showModalDelete, setShowModalDelete] = useState(false)
    // const [idPermintaan, setIdPermintaan] = useState(0)
    // const [idPencari, setIdPencari] = useState(0)
    // const [idPendonor, setIdPendonor] = useState(0)
    const [idPengajuan, setIdPengajuan] = useState(0)

    // const [showModalSelesai, setShowModalSelesai] = useState(false)

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setMessage("");
        setKeyword(query);
    }

    const _fetchDataMendonor = async () => {
        try{
            const res = await axios.get(`${url_api}/mendonor/data-mendonor/${data.detailUser.id}?search=${keyword}&page=${page}&limit=${limit}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            console.log(res.data)

            setDataMendonor(res.data.result)
            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
        }catch(err){
            console.log(err)
        }
    }
    
    useEffect(() => {
        _fetchDataMendonor()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, page, keyword, limit])

    return(
        <>
            <MenuPencari data={data}/>

            {/* Modal Delete */}
            <ModalDelete 
                data={{ idPengajuan: idPengajuan }}
                show={showModalDelete}
                onClose={() => setShowModalDelete(false)}
            />

            {/* Modal Selesai */}
            {/* <ModalSelesai 
                data={{ 
                    idPermintaan: idPermintaan,
                    idPencari: idPencari,
                    idPendonor: idPendonor,
                    idPengajuan: idPengajuan
                }}
                show={showModalSelesai}
                onClose={() => setShowModalSelesai(false)}
            /> */}

            <Container className="mt-5">

                <div className="alert alert-info">
                    <h5>Petunjuk : </h5>
                    <div>1. Jika data permintaan sudah berwarna hijau, berarti data permintaan tersebut telah selesai</div>
                    <div>2. Jika data permintaan tidak berwarna hijau, berarti data permintaan tersebut belum selesai</div>
                    <div>3. Data permintaan yang telah selesai, data kontak Pendonor akan hilang</div>
                </div>

                <Card style={cardStyle}>
                    <h3>Data Mendonor</h3>
                    <div>Data Pendonor yang telah Anda terima untuk mendonorkan darah mereka. Silahkan hubungi Pendonor melalui kontak mereka yang tertera</div><hr/>

                    <form onSubmit={searchData} style={{marginTop: '1%'}}>
                        <Row>
                            <Col md={4} xs={7}>
                                <input 
                                    type="text"
                                    className="form-control"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    placeholder="Nama Pendonor..."
                                />
                            </Col>
                            <Col md={6}>
                                <button type="submit" className="btn btn-outline-danger">Cari</button>
                            </Col>
                            <Col md={2}>
                                <select
                                    name="limit"
                                    id="limit"
                                    className="form-select"
                                    onChange={(e) => {
                                        setLimit(e.target.value)
                                    }}
                                >
                                    <option value="">Limit Data</option>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={150}>150</option>
                                    <option value={200}>200</option>
                                </select>
                            </Col>
                        </Row>
                    </form>

                    <Table style={{marginTop: '2%'}} responsive={true} striped bordered>
                        <thead>
                            <tr>
                                <th>Nama Pendonor</th>
                                <th>No HP</th>
                                <th>Jumlah Kantong</th>
                                <th>Tgl Pengajuan</th>
                                <th>Tgl Permintaan</th>
                                <th>Tgl Mendonor</th>
                                <th>Gol Darah</th>
                                <th>Opsi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataMendonor.length > 0 ?
                            dataMendonor.map((val, key) => {
                                return (
                                    <tr key={key} style={{backgroundColor: val.done === "yes" ? "#8fff5f" : null }}>
                                        <td>{val.fullname_pendonor}</td>
                                        <td>+62 {val.done === "yes" ? "xxx xxxx xxxx" : val.nohp_pendonor}</td>
                                        <td>{val.jumlah_kantong === 0 ? "Belum ada" : `${val.jumlah_kantong} kantong darah`}</td>
                                        <td>{moment(val.tgl_pengajuan).format('DD/MM/YYYY')}</td>
                                        <td>{moment(val.tgl_pengajuan).format('DD/MM/YYYY')}</td>
                                        <td>{moment(val.permintaan_mendonor.tgl_mendonor).format('DD/MM/YYYY')}</td>
                                        <td>
                                            { val.permintaan_mendonor.gol_darah === 'APLUS' ? 'A+' : val.permintaan_mendonor.gol_darah === 'ABPLUS' ? 'AB+' : val.permintaan_mendonor.gol_darah === 'OPLUS' ? 'O+'
                                            : val.permintaan_mendonor.gol_darah }
                                        </td>
                                        <td>
                                            {/* {val.done === 'no' ?

                                            <button className="btn btn-success btn-sm" onClick={() => {
                                                setShowModalSelesai(true)
                                                setIdPermintaan(val.permintaanMendonorId)
                                                setIdPencari(val.pencariId)
                                                setIdPendonor(val.pendonorId)
                                                setIdPengajuan(val.id)
                                            }}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </button>

                                            : null}&nbsp; */}

                                            <button className="btn btn-danger btn-sm" onClick={() => {
                                                setShowModalDelete(true)
                                                // setIdPermintaan(val.permintaanMendonorId)
                                                setIdPengajuan(val.id)
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={8} align="center">Belum ada data</td>
                            </tr>
                            }
                        </tbody>
                    </Table>

                    <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                    <div><font color="red">{message}</font></div>

                    {dataMendonor.length > 0 ? 
                    <nav key={rows} style={{marginTop: '1%'}}>
                        <ReactPaginate
                            previousLabel={"< Previous"}
                            nextLabel={"Next >"}
                            pageCount={Math.min(10, pages)}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeLinkClassName={"page-item active"}
                            disabledLinkClassName={"page-item disabled"}
                        />
                    </nav>
                    : null}

                </Card>
            </Container><br/>
        </>
    )
}

export default Layout;