import { Card, Col, Container, Row, Spinner, Modal } from "react-bootstrap";
import { url_static } from "../../config";
import imgUser from './user.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faWarning, faCog } from '@fortawesome/free-solid-svg-icons';
import ModalSettings from "./ModalSettings";
import { useState } from "react";
import axios from "axios";
import { url_api } from "../../config";
import { MenuPencari } from "../../components/MenuPencari";

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'red',
    padding: '25px'
}

const Layout = ({data}) => {
    const [showModal, setShowModal] = useState(false)
    const _handleShow = () => setShowModal(true)
    const _handleClose = () => setShowModal(false)

    const [showModalDelete, setShowModalDelete] = useState(false)
    const _handleShowDelete = () => setShowModalDelete(true)
    const _handleCloseDelete = () => setShowModalDelete(false)

    // delete pencari
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")

    const _deletePencari = async () => {
        try{
            const res = await axios.delete(`${url_api}/pencari/${data.user.id}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })
    
            if(res.data.success){
                setSuccess(res.data.success)
                setMessage(res.data.message)
                setLoading(false)

                // hapus cookies
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('role')
    
                setTimeout(() => {
                    window.location.href = "/login";
                }, 2000)
            }
        }catch(err){
            console.log(err)
        }
    }
    
    return(
        <>
            <MenuPencari data={data}/>

            {/* Modal Settings */}
            <ModalSettings 
                data={data}
                show={showModal} 
                onClose={_handleClose}
            />

            <Container className="mt-5">
                
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <Card style={cardStyle}>
                            
                            <Row>
                                <Col md={8}>
                                    <h5>Profil</h5>
                                </Col>
                                <Col md={4}>
                                    <button className="btn btn-secondary" onClick={_handleShow}><FontAwesomeIcon icon={faCog} /> Pengaturan</button>
                                </Col>
                            </Row>
                            <hr/>

                            <center>
                                {data.detailUser.foto === '' ?
                                <img src={imgUser} className="img-fluid" alt="Img User" style={{height: '150px', borderRadius: '50%'}} />
                                :
                                <img src={`${url_static}/img-pencari/${data.detailUser.foto}`} className="img-fluid" alt="Img User" style={{height: '180px', borderRadius: '50%'}} />
                                }
                            </center>
                            <h4 className="mt-3 text-center">{data.user.firstname} {data.user.lastname}</h4>
                            <h6 className="text-center">+62 {data.user.no_hp} {data.user.verified_nohp === 'no' ? <a href="#s" style={{color: 'red'}} title="Belum Terverifikasi"><FontAwesomeIcon icon={faTimes} /></a> : <a href="#s" style={{color: 'green'}} title="Terverifikasi"><FontAwesomeIcon icon={faCheckCircle} /></a>}</h6><hr/>

                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td>Email</td>
                                        <td>:</td>
                                        <td>{data.user.email} {data.user.verified_email === 'no' ? <a href="#s" style={{color: 'red'}} title="Belum Terverifikasi"><FontAwesomeIcon icon={faTimes} /></a> : <a href="#s" style={{color: 'green'}} title="Terverifikasi"><FontAwesomeIcon icon={faCheckCircle} /></a>}</td>
                                    </tr>
                                    <tr>
                                        <td>Tempat Lahir</td>
                                        <td>:</td>
                                        <td>{data.detailUser.tempat_lahir === '' ? '-' : data.detailUser.tempat_lahir}</td>
                                    </tr>
                                    <tr>
                                        <td>Tanggal Lahir</td>
                                        <td>:</td>
                                        <td>{data.detailUser.tgl_lahir === '0000-00-00' ? '-' : data.detailUser.tgl_lahir}</td>
                                    </tr>
                                    <tr>
                                        <td>Jenis Kelamin</td>
                                        <td>:</td>
                                        <td>{data.detailUser.jenis_kelamin}</td>
                                    </tr>
                                </tbody>
                            </table><hr/>

                            {/* Alamat */}
                            <h5>Alamat Lengkap</h5>
                            <div>{data.detailUser.alamat === '' ? 'Belum memiliki alamat detail' : data.detailUser.alamat}</div><hr/>

                            <Card bg="danger" text="white">
                                <Card.Body>
                                    <h4><FontAwesomeIcon icon={faWarning} /> Danger Area</h4><hr/>
                                    <h6>Hapus Akun</h6>
                                    <div>Hapus akun hapus akun Anda jika sudah tidak digunakan lagi</div>

                                    <a href="#s" className="btn btn-outline-light mt-3" onClick={() => _handleShowDelete()}>Hapus Akun</a>
                                </Card.Body>
                            </Card>
                        </Card>
                    <br/></Col>
                </Row>

            </Container>

            <Modal show={showModalDelete} onHide={_handleCloseDelete} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        {success ?
                        <div className="alert alert-success">{message}. Anda akan dikeluarkan secara otomatis</div>
                        : null}

                        Yakin ingin menghapus akun Anda ?
                    </Modal.Body>
                <Modal.Footer>
                    {loading ?
                    <button className="btn btn-secondary" disabled>Tidak</button>
                    :
                    <button className="btn btn-secondary" onClick={_handleCloseDelete}>Tidak</button>
                    }

                    {loading ? 
                    <button className="btn btn-danger" disabled><Spinner size="sm"/></button>
                    :
                    <button className="btn btn-danger" onClick={() => {
                        setTimeout(() => {
                            _deletePencari()
                        }, 500)
                        
                        setLoading(true)
                    }}>
                        Ya
                    </button>
                    }
                    
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Layout;