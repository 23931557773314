import axios from "axios"
import { Formik } from "formik"
import { Col, Row, Spinner } from "react-bootstrap"
import { url_api } from "../../../config"
import { useState } from "react"

const EditInformasi = ({data}) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [message, setMessage] = useState("")

    const _saveProfil = async (dataForm) => {
        try{
            const res = await axios.post(`${url_api}/profil/change-pencari/${data.idUser}`, {
                firstname: dataForm.firstname,
                lastname: dataForm.lastname,
                tempat_lahir: dataForm.tempat_lahir,
                tgl_lahir: dataForm.tgl_lahir,
                jenis_kelamin: dataForm.jenis_kelamin,
                alamat: dataForm.alamat
            }, { headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`} })

            if(res.data.success){
                setSuccess(res.data.success)
                setMessage(res.data.message)
                setLoading(false)

                setTimeout(() => {
                    window.location.href = "/profil";
                }, 1500)
            }
        }catch(err){
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>

            {success === true ? 
            <div className="alert alert-success">{message}</div>
            : success === false ?
            <div className="alert alert-danger">{message}</div>
            : null}

            <Formik
                initialValues={{
                    firstname: data.firstname,
                    lastname: data.lastname,
                    tempat_lahir: data.tempat_lahir,
                    tgl_lahir: data.tgl_lahir,
                    jenis_kelamin: data.jenis_kelamin,
                    alamat: data.alamat
                }}
                onSubmit={(values) => {
                    setTimeout(() => {
                        _saveProfil(values)
                    }, 500)
                    
                    setLoading(true)
                }}
            >

            {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="firstname" className="mb-2"><b>Nama Depan</b></label>
                                <input 
                                    type="text" 
                                    id="firstname" 
                                    name="firstname" 
                                    className="form-control"
                                    value={values.firstname}
                                    onChange={handleChange}
                                    placeholder="Nama Depan"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="lastname" className="mb-2"><b>Nama Belakang</b></label>
                                <input 
                                    type="text" 
                                    id="lastname" 
                                    name="lastname" 
                                    className="form-control"
                                    value={values.lastname}
                                    onChange={handleChange}
                                    placeholder="Nama Belakang"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="tempat_lahir" className="mb-2"><b>Tempat Lahir</b></label>
                                <input 
                                    type="text" 
                                    id="tempat_lahir" 
                                    name="tempat_lahir" 
                                    className="form-control"
                                    value={values.tempat_lahir}
                                    onChange={handleChange}
                                    placeholder="Tempat Lahir"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="tgl_lahir" className="mb-2"><b>Tanggal Lahir</b></label>
                                <input 
                                    type="date" 
                                    id="tgl_lahir" 
                                    name="tgl_lahir" 
                                    className="form-control"
                                    value={values.tgl_lahir}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="jenis_kelamin" className="mb-2"><b>Jenis Kelamin</b></label>
                                <select
                                    name="jenis_kelamin"
                                    id="jenis_kelamin"
                                    className="form-select"
                                    onChange={handleChange}
                                >
                                    <option value="">...</option>
                                    <option value="Laki-laki" selected={values.jenis_kelamin === "Laki-laki" ? true : false}>Laki-laki</option>
                                    <option value="Perempuan" selected={values.jenis_kelamin === "Perempuan" ? true : false}>Perempuan</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="alamat" className="mb-2"><b>Alamat</b></label>
                                <input 
                                    type="text" 
                                    id="alamat" 
                                    name="alamat" 
                                    className="form-control"
                                    value={values.alamat}
                                    onChange={handleChange}
                                    placeholder="Alamat Lengkap"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            {loading ?
                            <button className="btn btn-primary w-100" disabled>Loading... <Spinner size="sm" /></button>
                            :
                            <button type="submit" className="btn btn-primary w-100">Simpan</button>
                            }
                        </Col>
                    </Row><hr/>
                </form>
            )}

            </Formik>
        </>
    )
}

export default EditInformasi