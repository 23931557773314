import { MenuPencari } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { url_api, getCurrentDate } from "../../config";
import ReactPaginate from "react-paginate";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ModalDelete from "./ModalDelete";

const Layout = ({data}) => {
    const [dataMendonor, setDataPermintaan] = useState([])
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [message, setMessage] = useState("")

    // modal delete
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [idPermintaan, setIdPermintaan] = useState(0)

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const _fetchDataPermintaan = async () => {
        try{
            const res = await axios.get(`${url_api}/mendonor/permintaan-mendonor/${data.detailUser.id}?page=${page}&limit=${limit}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setDataPermintaan(res.data.result)
            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchDataPermintaan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.detailUser.id, limit, page])

    return(
        <>
            <MenuPencari data={data}/>

            {/* Modal Delete */}
            <ModalDelete 
                data={{ idPermintaan: idPermintaan }}
                show={showModalDelete}
                onClose={() => setShowModalDelete(false)}
            />

            <Container className="mt-4">

                <Card>
                    <Card.Body>
                        <h4>Data Permintaan Mendonor</h4>
                        <div>Ini merupakan data permintaan mendonor yang Anda buat</div><hr/>

                        <div className="alert alert-info">
                            <h5>Petunjuk : </h5>
                            <div>1. Jika data permintaan sudah berwarna hijau, berarti data permintaan tersebut telah selesai</div>
                            <div>2. Jika data permintaan tidak berwarna hijau, berarti data permintaan tersebut belum selesai</div>
                        </div>

                        <Row>
                            <Col md={3}>
                                <select
                                    name="limit"
                                    id="limit"
                                    className="form-select"
                                    onChange={(e) => {
                                        setLimit(e.target.value)
                                    }}
                                >
                                    <option value="">Limit Data</option>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={150}>150</option>
                                    <option value={200}>200</option>
                                </select>
                            </Col>
                        </Row><br/>
                                
                        <Table responsive={true} striped bordered>
                            <thead>
                                <tr align="center">
                                    <th>TGL Permintaan</th>
                                    <th>TGL Mendonor</th>
                                    <th>Gol Darah</th>
                                    <th>Jumlah Kantong</th>
                                    <th>Keterangan</th>
                                    <th>Opsi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataMendonor.length > 0 ?
                                dataMendonor.map((val, key) => {
                                    return (
                                        <tr key={key} align="center" style={{backgroundColor: val.status_done === "yes" ? "#8fff5f" : null }}>                
                                            <td>{moment(val.tgl_permintaan).format('DD/MM/YYYY')}</td>
                                            <td>{moment(val.tgl_mendonor).format('DD/MM/YYYY')}</td>
                                            <td>
                                                { val.gol_darah === 'APLUS' ? 'A+' : val.gol_darah === 'ABPLUS' ? 'AB+' : val.gol_darah === 'OPLUS' ? 'O+'
                                                : val.gol_darah }
                                            </td>
                                            <td>{val.kantong_darah}</td>
                                            <td>{val.keterangan}</td>
                                            <td>
                                                <button className="btn btn-danger btn-sm" onClick={() => {
                                                    setShowModalDelete(true)
                                                    setIdPermintaan(val.id)
                                                }}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={8} align="center">Belum ada data</td>
                                </tr>
                                }
                            </tbody>
                        </Table>

                        <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                        <div><font color="red">{message}</font></div>

                        {dataMendonor.length > 0 ? 
                        <nav key={rows} style={{marginTop: '1%'}}>
                            <ReactPaginate
                                previousLabel={"< Previous"}
                                nextLabel={"Next >"}
                                pageCount={Math.min(10, pages)}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                pageLinkClassName={"page-link"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                activeLinkClassName={"page-item active"}
                                disabledLinkClassName={"page-item disabled"}
                            />
                        </nav>
                        : null}
                    </Card.Body>
                </Card>
            </Container>

        </>
    )
}

export default Layout;