import { useRouteMatch } from "react-router-dom"
import { useMemo } from "react"
import { useClient } from "../../components";
import { Navigation, RootProvider } from "./hoc";
import Router from "./Router";

import DashboardPendonor from "../Pendonor.Dashboard";
import DashboardPencari from '../Pencari.Dashboard';
import DashboardAdmin from "../Admin.Dashboard";
import ProfilAdmin from "../Admin.Profil";
import AdminManagePendonor from "../Admin.ManagePendonor";
import AdminDetailPendonor from "../Admin.DetailPendonor";
import ProfilPendonor from "../Pendonor.Profil";
import ProfilPencari from "../Pencari.Profil";
import PengajuanMendonor from "../Pencari.PengajuanMendonor";
import DataMendonor from "../Pencari.DataMendonor";
import DataPengajuan from "../Pendonor.DataPengajuan";
import DataPermintaanMendonor from "../Pencari.DataPermintaanMendonor";
import DashboardBankDarah from "../BankDarah.Dashboard";
import DashboardPMI from "../PMI.Dashboard";
import DetailPermintaanPMI from "../PMI.DetailPermintaan";
import DetailPermintaanBankDarah from "../BankDarah.DetailPermintaan";
import DetailPermintaanPendonor from "../Pendonor.DetailPermintaan";

const navigation = [
    {
        "title": "Dashboard Pendonor",
        "text": "Dashboard Pendonor",
        "component": DashboardPendonor,
        "path": "/pendonor",
        "icon": "blank",
        "exact": true,
        "permission": "pendonor"
    },
    {
        "title": "Dashboard Pencari Darah",
        "text": "Dashboard Pencari Darah",
        "component": DashboardPencari,
        "path": "/pencari",
        "icon": "blank",
        "exact": true,
        "permission": "pencari"
    },
    {
        "title": "Dashboard Admin",
        "text": "Dashboard Admin",
        "component": DashboardAdmin,
        "path": "/admin",
        "icon": "blank",
        "exact": true,
        "permission": "admin"
    },
    {
        "title": "Profil Admin",
        "text": "Profil Admin",
        "component": ProfilAdmin,
        "path": "/profil",
        "icon": "blank",
        "exact": true,
        "permission": "admin"
    },
    {
        "title": "Manage Pendonor",
        "text": "Manage Pendonor",
        "component": AdminManagePendonor,
        "path": "/manage-pendonor",
        "icon": "blank",
        "exact": true,
        "permission": "admin"
    },
    {
        "title": "Detail Pendonor",
        "text": "Detail Pendonor",
        "component": AdminDetailPendonor,
        "path": "/detail-pendonor/:idUser",
        "icon": "blank",
        "exact": true,
        "permission": "admin"
    },
    {
        "title": "Profil Pendonor",
        "text": "Profil Pendonor",
        "component": ProfilPendonor,
        "path": "/profil",
        "icon": "blank",
        "exact": true,
        "permission": "pendonor"
    },
    {
        "title": "Profil Pencari",
        "text": "Profil Pencari",
        "component": ProfilPencari,
        "path": "/profil",
        "icon": "blank",
        "exact": true,
        "permission": "pencari"
    },
    {
        "title": "Pengajuan Mendonor",
        "text": "Pengajuan Mendonor",
        "component": PengajuanMendonor,
        "path": "/pengajuan-mendonor",
        "icon": "blank",
        "exact": true,
        "permission": "pencari"
    },
    {
        "title": "Data Mendonor",
        "text": "Data Mendonor",
        "component": DataMendonor,
        "path": "/data-mendonor",
        "icon": "blank",
        "exact": true,
        "permission": "pencari"
    },
    {
        "title": "Data Pengajuan",
        "text": "Data Pengajuan",
        "component": DataPengajuan,
        "path": "/data-pengajuan",
        "icon": "blank",
        "exact": true,
        "permission": "pendonor"
    },
    {
        "title": "Detail Permintaan",
        "text": "Detail Permintaan",
        "component": DetailPermintaanPendonor,
        "path": "/detail-permintaan",
        "icon": "blank",
        "exact": true,
        "permission": "pendonor"
    },
    {
        "title": "Data Pengajuan",
        "text": "Data Pengajuan",
        "component": DataPermintaanMendonor,
        "path": "/permintaan-mendonor",
        "icon": "blank",
        "exact": true,
        "permission": "pencari"
    },
    {
        "title": "Dashboard PMI",
        "text": "Dashboard PMI",
        "component": DashboardPMI,
        "path": "/pmi",
        "icon": "blank",
        "exact": true,
        "permission": "pmi"
    },
    {
        "title": "Dashboard Bank Darah",
        "text": "Dashboard Bank Darah",
        "component": DashboardBankDarah,
        "path": "/bank-darah",
        "icon": "blank",
        "exact": true,
        "permission": "bank-darah"
    },
    {
        "title": "Detail Permintaan",
        "text": "Detail Permintaan",
        "component": DetailPermintaanPMI,
        "path": "/detail-permintaan/:idPermintaan",
        "icon": "blank",
        "exact": true,
        "permission": "pmi"
    },
    {
        "title": "Detail Permintaan",
        "text": "Detail Permintaan",
        "component": DetailPermintaanBankDarah,
        "path": "/detail-permintaan/:idPermintaan",
        "icon": "blank",
        "exact": true,
        "permission": "bank-darah"
    },
]

const Main = () => {
    const {path} = useRouteMatch()
    const client = useClient();

    const items = useMemo(() => {
        return navigation.filter(({ permission }) => {
            return permission ? permission === client.role : true;
        });
    }, [client.role])

    return(
        <RootProvider>
            <Navigation base={path} navigation={items}>
                <Router />
            </Navigation>
        </RootProvider>
    )
}

export default Main;