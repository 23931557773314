import LogoPMI from '../logo-pmi.png';
import { Row, Col } from "react-bootstrap";
import '../kartu.css';
import ImgDarahAnimasi from '../darah-animasi.png';
import moment from 'moment';

const KartuPendonor = ({data}) => {
    return(
        <>
            <div id="kartu">
                <Row>
                    <Col md={9}>
                        <div id="header-kartu"></div>
                    </Col>
                    <Col md={3}>
                        <img src={LogoPMI} alt="Logo PMI" id='logo-pmi' />
                    </Col>
                </Row>

                <div id='header-content'>
                    <h2 align="center" className='mt-2'>Kartu Donor Darah</h2>
                    <div style={{fontSize: '1.5em', fontWeight: 'bold', textAlign: 'center'}}><u>UDD PMI PROVINSI SULUT</u></div>
                    <div style={{fontSize: '12px', textAlign: 'center'}}>Jl. R.W Mongisidi Malalayang Satu Barat Manado</div>
                    <div style={{fontSize: '12px', textAlign: 'center'}}>Telp. 085380203332</div>
                </div>

                <div id="content">
                    <div className='row'>
                        <div className='col-md-3 col-3'>
                            <img src={ImgDarahAnimasi} style={{maxWidth: '100%', height: '120px'}} alt="Darah Animasi" />
                            <h1 style={{textAlign: 'center'}} className='mt-2'>{data.detailUser.gol_darah === 'APLUS' ? 'A+' : data.detailUser.gol_darah === 'ABPLUS' ? 'AB+' : data.detailUser.gol_darah === 'OPLUS' ? 'O+' : data.detailUser.gol_darah}</h1>
                        </div>
                        <div className='col-md-9 col-9'>
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td><b>NO. ID</b></td>
                                        <td>:</td>
                                        <td><b>{data.detailUser.nomor_id}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Nama</b></td>
                                        <td>:</td>
                                        <td><b>{data.user.firstname} {data.user.lastname}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Tpt. Lahir</b></td>
                                        <td>:</td>
                                        <td><b>{data.detailUser.tempat_lahir}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Tgl. Lahir</b></td>
                                        <td>:</td>
                                        <td><b>{moment(data.detailUser.tgl_lahir).format("Do MMMM YYYY")}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Alamat</b></td>
                                        <td>:</td>
                                        <td><b>{data.detailUser.alamat}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Telp</b></td>
                                        <td>:</td>
                                        <td><b>0{data.user.no_hp}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Jns Klmn</b></td>
                                        <td>:</td>
                                        <td><b>{data.detailUser.jenis_kelamin === 'Laki-laki' ? 'Pria' : 'Wanita'}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default KartuPendonor;