import { Formik } from "formik";
import { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastAlert } from "../../../components";
import axios from "axios";
import { url_api } from "../../../config";

const ChangePass = ({idUser}) => {
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const _savePassword = async (data, idUser) => {
        try{
            const res = await axios.put(`${url_api}/profil/change-pass/${idUser}`, {
                old_pass: data.old_pass,
                new_pass: data.new_pass,
                reply_new_pass: data.reply_new_pass
            }, { headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`} })

            if(res.data.success){
                setShowToast(true)
                setLoading(false)
                setMessage(res.data.message)
                setSuccess(res.data.success)

                setTimeout(() => {
                    window.location.href = "/profil";
                }, 1500)
            }
        }catch(err){
            setShowToast(true)
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>

            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Formik
                initialValues={{
                    old_pass: '',
                    new_pass: '',
                    reply_new_pass: ''
                }}
                onSubmit={(values) => {
                    setTimeout(() => {
                        _savePassword(values, idUser)
                    }, 1000)

                    setLoading(true)
                }}
            >

            {({ values, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="mb-2" htmlFor="old_pass"><b>Password Lama</b></label>
                        <input 
                            type="password"
                            name="old_pass"
                            id="old_pass"
                            className="form-control"
                            onChange={handleChange}
                            value={values.old_pass}
                            placeholder="Masukkan Password Lama"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-2" htmlFor="new_pass"><b>Password Baru</b></label>
                        <input 
                            type="password"
                            name="new_pass"
                            id="new_pass"
                            className="form-control"
                            onChange={handleChange}
                            value={values.new_pass}
                            placeholder="Masukkan Password Baru"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-2" htmlFor="c_new_pass"><b>Ulangi Password Baru</b></label>
                        <input 
                            type="password"
                            name="reply_new_pass"
                            id="reply_new_pass"
                            className="form-control"
                            onChange={handleChange}
                            value={values.reply_new_pass}
                            placeholder="Masukkan Password Baru"
                            required
                        />
                    </div>
                    <Row>
                        <Col md={3}>
                            {loading ?
                            <button className="btn btn-primary w-100" disabled>Loading... <Spinner size="sm" /></button>
                            :
                            <button type="submit" className="btn btn-primary w-100">Simpan</button>
                            }
                        </Col>
                    </Row>
                </form>
            )}

            </Formik>
        </>
    )
}

export default ChangePass;