import { Formik } from 'formik'
import { Col, Row, Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import axios from 'axios';
import { ToastAlert } from '../../components';
import { url_api } from '../../config';
import { useHistory } from 'react-router-dom'
import imgPMI from './blood-bank.png';

const Layout = () => {
    const history = useHistory()
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const _handleLogin = async (data) => {
        try{
            const res = await axios.post(`${url_api}/auth/login`, {
                no_hp: data.no_hp,
                password: data.password,
                role: data.role
            })
    
            if(res.data.success){
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('role', res.data.role)
    
                history.push(`/${res.data.role}`) // redirect
            }
        }catch(err){
            setShowToast(true)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>

            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Container className="pt-5 pb-5">
                <Row className='justify-content-md-center'>
                    <Col md={5}>

                        <Card>
                            <Card.Body>

                                <center><img src={imgPMI} alt="Img PMI" className='img-fluid' /></center><hr/>

                                <p>Masuk sebagai PMI atau Bank Darah</p>

                                <Formik
                                    initialValues={{
                                        no_hp: '',
                                        password: '',
                                        role: ''
                                    }}
                                    onSubmit={(values) => {
                                        setTimeout(() => {
                                            _handleLogin(values)
                                            setLoading(false)
                                        }, 1000)

                                        setLoading(true)
                                    }}
                                >

                                    {({values, errors, touched, handleSubmit, handleChange}) => (
                                        
                                        <form onSubmit={handleSubmit} className="mt-3">

                                            <div className='mb-3'>
                                                <label htmlFor='no_hp' className='mb-2'><b>Nomor HP</b></label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon1">+62</span>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id='no_hp' 
                                                        name='no_hp' 
                                                        placeholder="Nomor HP"
                                                        onChange={handleChange} 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='password' className='mb-2'><b>Password</b></label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faLock} /></span>
                                                    <input 
                                                        type="password" 
                                                        className="form-control" 
                                                        id='password' 
                                                        name='password' 
                                                        placeholder="Password"
                                                        onChange={handleChange} 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-2'>
                                                    <label htmlFor='role' className='mb-2'><b>Sebagai</b></label>
                                                    <select className='form-select' name="role" id='role' onChange={handleChange} required>
                                                        <option value="">...</option>
                                                        <option value="pmi">PMI</option>
                                                        <option value="bank-darah">Bank Darah</option>
                                                    </select>
                                                </div>

                                            <Row className='mt-4'>
                                                <Col md={5}>
                                                    {loading ?
                                                    <button className='btn btn-outline-danger w-100' disabled>Loading...</button>
                                                    :
                                                    <button type='submit' className='btn btn-outline-danger w-100'>Login</button>
                                                    }
                                                </Col>
                                            </Row>
                                        </form>

                                    )}

                                </Formik>

                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                <div className='text-center mt-3'>Tidak punya akses? <a href="/" style={{textDecoration: 'none'}}>Kembali</a></div>
            </Container>

        </>
    )
}

export default Layout;