import pictureOne from './picture-1.jpg';
import pictureTwo from './picture-2.jpg';
import pictureThree from './picture-3.jpg';
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Formik} from 'formik'
import '../../form.css'
import axios from 'axios'
import { url_api } from '../../config';
import * as Yup from 'yup';
import { ToastAlert } from '../../components';
import Otp from './Otp';

// Styles
const cardRegis = {
    borderRadius: '20px',
    borderColor: '#ff2323',
    padding: '30px'
}

const Schema = Yup.object().shape({
    firstname: Yup.string().required('tidak boleh kosong'),
    lastname: Yup.string().required('tidak boleh kosong'),
    email: Yup.string().required('tidak boleh kosong').email('tidak valid'),
    no_hp: Yup.string().max(13, 'batas 13 digit').required('tidak boleh kosong'),
    password: Yup.string().required('tidak boleh kosong'),
    c_pass: Yup.string().oneOf([Yup.ref('password')], 'tidak cocok dengan password').required('tidak boleh kosong'),
    jenis_kelamin: Yup.string().required('tidak boleh kosong'),
    alamat: Yup.string().required('tidak boleh kosong'),
})

const Layout = () => {
    const [bgStyle, setBgStyle] = useState({
        height: '100%',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    })

    // form
    const [showToast, setShowToast] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        const pictureArray = [pictureOne, pictureTwo, pictureThree];
        const randomIndex = Math.floor(Math.random() * pictureArray.length);
        const selectedPicture = pictureArray[randomIndex];

        setBgStyle({
            backgroundImage: `url(${selectedPicture})`,
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        })

    }, [])

    const _regisPencari = async (data) => {
        try{
            const res = await axios.post(`${url_api}/auth/regis/pencari`, {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                no_hp: data.no_hp,
                password: data.password,
                c_pass: data.c_pass,
                role: 'pencari',
                jenis_kelamin: data.jenis_kelamin,
                alamat: data.alamat,
            })

            // simpan data di cookies - simpan data sementara
            sessionStorage.setItem('nextStep', true); // untuk next ke otp
            sessionStorage.setItem('no_hp', data.no_hp)
    
            if(res.data.success){ // jika berhasil
                // mengirim kode otp
                _getCode(data.no_hp);

                setShowToast(true)
                setMessage(res.data.message)
                setSuccess(res.data.success)
            }
        }catch(err){
            setShowToast(true)
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    // mengirim kode otp ke sms
    const _getCode = async (nomor_hp) => {
        await axios.get(`${url_api}/auth/otp/get-code`, {
            params: {
                phonenumber: `+62${nomor_hp}`,
                channel: 'sms'
            }
        })
        .then(data => console.log(data))
        .catch(err => console.log(err))
    }

    return(
        <div style={bgStyle} className="bg">
            <Container className='pt-5 pb-5'>

                <ToastAlert 
                    body={message}
                    variant={success ? 'success' : 'danger'}
                    onShow={showToast}
                    onClose={() => setShowToast(false)}
                    success={success}
                    containerPosition="fixed"
                    position="top-end"
                />

                {sessionStorage.getItem('nextStep') ?

                // Verifikasi Kode OTP
                <Otp />

                :

                <Row className='justify-content-md-center'>
                    <Col md={7}>
                        <div className='card' style={cardRegis}>
                            <h3 align="center" style={{color: '#ff2323'}}>Pendonor Sukarela</h3>
                            <div align="center">Daftar sekarang dan temukan pendonor sukarela dengan lebih cepat dan efektif</div><hr/>

                            <Formik
                                initialValues={{
                                    firstname: '',
                                    lastname: '',
                                    email: '',
                                    no_hp: '',
                                    password: '',
                                    c_pass: '',
                                    jenis_kelamin: '',
                                    alamat: ''
                                }}
                                validationSchema={Schema}
                                onSubmit={(values) => {
                                    setTimeout(() => {
                                        _regisPencari(values)

                                        setShowToast(true)
                                        setSuccess(true)
                                        setLoading(false)
                                    }, 1000)

                                    setLoading(true)
                                }}
                            >

                                {({values, errors, touched, handleSubmit, handleChange}) => (

                                    <form method='post' onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='firstname' className='mb-1'><b>Nama Depan</b></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="firstname" 
                                                        id='firstname'
                                                        onChange={handleChange}
                                                        placeholder='Masukkan Nama Depan'
                                                    />
                                                    {errors.firstname && touched.firstname ? <span className='text-muted'><font color="red">{errors.firstname}</font></span> : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='lastname' className='mb-1'><b>Nama Belakang</b></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="lastname" 
                                                        id='lastname' 
                                                        onChange={handleChange}
                                                        placeholder='Masukkan Nama Belakang'
                                                    />
                                                    {errors.lastname && touched.lastname ? <span className='text-muted'><font color="red">{errors.lastname}</font></span> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='email' className='mb-1'><b>Email</b></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="email" 
                                                        id='email'
                                                        onChange={handleChange} 
                                                        placeholder='Masukkan Email'
                                                    />
                                                    {errors.email && touched.email ? <span className='text-muted'><font color="red">{errors.email}</font></span> : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='no_hp' className='mb-1'><b>Nomor HP</b></label>
                                                    <div className="input-group">
                                                        <span className="input-group-text">+62</span>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="no_hp" 
                                                            id='no_hp'
                                                            onChange={handleChange} 
                                                            placeholder='Masukkan Nomor HP'
                                                        />
                                                    </div>
                                                    {errors.no_hp && touched.no_hp ? <span className='text-muted'><font color="red">{errors.no_hp}</font></span> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='password' className='mb-1'><b>Password</b></label>
                                                    <input 
                                                        type="password" 
                                                        className="form-control" 
                                                        name="password" 
                                                        id='password'
                                                        onChange={handleChange} 
                                                        placeholder='Masukkan Password'
                                                    />
                                                    {errors.password && touched.password ? <span className='text-muted'><font color="red">{errors.password}</font></span> : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='c_pass' className='mb-1'><b>Ulangi Password</b></label>
                                                    <input 
                                                        type="password" 
                                                        className="form-control" 
                                                        name="c_pass" 
                                                        id='c_pass' 
                                                        onChange={handleChange}
                                                        placeholder='Ulangi Password'
                                                    />
                                                    {errors.c_pass && touched.c_pass ? <span className='text-muted'><font color="red">{errors.c_pass}</font></span> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='jenis_kelamin' className='mb-1'><b>Pilih Jenis Kelamin</b></label>
                                                    <select className='form-select' name="jenis_kelamin" onChange={handleChange}>
                                                        <option value="">...</option>
                                                        <option value="Laki-laki">Laki-laki</option>
                                                        <option value="Perempuan">Perempuan</option>
                                                    </select>
                                                    {errors.jenis_kelamin && touched.jenis_kelamin ? <span className='text-muted'><font color="red">{errors.jenis_kelamin}</font></span> : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <label htmlFor='alamat' className='mb-1'><b>Alamat</b></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="alamat" 
                                                        id='alamat' 
                                                        onChange={handleChange}
                                                        placeholder='Masukkan Alamat Lengkap'
                                                    />
                                                    {errors.alamat && touched.alamat ? <span className='text-muted'><font color="red">{errors.alamat}</font></span> : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col md={5}>
                                                {loading ? 
                                                <button type="button" className='btn btn-outline-danger w-100' disabled>Loading...</button>
                                                :
                                                <button type="submit" className='btn btn-outline-danger w-100'>Daftar</button>
                                                }
                                            </Col>
                                        </Row><hr/>

                                        <div align="center">Anda tidak mencari pendonor? <a href="/" style={{textDecoration: 'none'}}>Kembali</a></div>
                                    </form>

                                )}

                            </Formik>
                        </div>
                    </Col>
                </Row>

                }
            </Container>
        </div>
    )
}

export default Layout;