import Container from "react-bootstrap/Container";

const styles = {
    backgroundColor: '#ff2323',
    padding: '20px',
    color: '#ffffff',
    fontFamily: 'Open Sans'
}

export const Footer = () => {
    return(
        <>
            <div id="footer" style={styles}>
                <Container>
                    <center><span>© 2023 Pendonor Sukarela. All Rights Reserved.</span></center>
                </Container>
            </div>
        </>
    )
}