import { useEffect, useMemo, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import axios from 'axios';
import { url_api } from '../../config';
import EditInformasi from "./components/EditInformasi";
import ChangePass from "./components/ChangePass";

const styleLabel = {
    backgroundColor: '#ff2d2d',
    color: 'white',
    padding: '0.5rem',
    borderRadius: '0.3rem',
    cursor: 'pointer',
    marginTop: '1rem',
    width: '20%',
    textAlign: 'center'
}

const ModalSettings = ({data, show, onClose =() => {} }) => {
    const [file, setFile] = useState(null)
    const [fileDataUrl, setFileDataUrl] = useState(null)
    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    // use state for upload photo
    const [loadingPhoto, setLoadingPhoto] = useState(false)
    const [messagePhoto, setMessagePhoto] = useState("")
    const [successPhoto, setSuccessPhoto] = useState(null)

    useEffect(() => {
        let fileReader, isCancel = false;

        if(file){
            fileReader = new FileReader()
            fileReader.onload = (e) => {
                const {result} = e.target;
                if(result && !isCancel){
                    setFileDataUrl(result)
                }
            }

            fileReader.readAsDataURL(file)
        }

        return () => {
            isCancel = true;
            if(fileReader && fileReader.readyState === 1){
                fileReader.abort()
            }
        }
    }, [file])

    const defaultData = useMemo(() => {
        if(!show) return {}

        return {
            idUser: data.user.id,
            firstname: data.user.firstname,
            lastname: data.user.lastname,
            tempat_lahir: data.detailUser.tempat_lahir,
            tgl_lahir: data.detailUser.tgl_lahir,
            alamat: data.detailUser.alamat,
            foto: data.detailUser.foto,
            gol_darah: data.detailUser.gol_darah,
            jenis_kelamin: data.detailUser.jenis_kelamin,
        }

    }, [data, show])

    const _saveFoto = async (data) => {
        const formData = new FormData()
        formData.append('foto', data.foto)

        try{
            const res = await axios.post(`${url_api}/upload/foto-pendonor/${defaultData.idUser}`, formData, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })
    
            if(res.data.success){
                setSuccessPhoto(res.data.success)
                setMessagePhoto(res.data.message)
                setLoadingPhoto(false)

                setTimeout(() => {
                    window.location.href = "/profil";
                }, 1500)
            }
        }catch(err){
            setLoadingPhoto(false)
            setMessagePhoto(err.response.data.message)
            setSuccessPhoto(err.response.data.success)
        }
    }

    return(
        <>
            <Modal show={show} onHide={onClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Pengaturan Profil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    {/* Menu Pengaturan */}
                    <Tabs
                        defaultActiveKey="upload-foto"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        {/* Upload Foto */}
                        <Tab eventKey="upload-foto" title="Upload Foto">
                            <h4 className="mt-4">Upload Foto</h4>
                            <div>Ganti foto mu untuk mempermudah para pencari darah mengenal mu</div><hr/>

                            {successPhoto === true ?
                            <div className="alert alert-success">{messagePhoto}</div>
                            : successPhoto === false ?
                            <div className="alert alert-danger">{messagePhoto}</div>
                            : null}

                            <Formik
                                initialValues={{ foto: '' }}
                                onSubmit={(values) => {
                                    setTimeout(() => {
                                        _saveFoto(values)
                                    }, 500)

                                    setLoadingPhoto(true)
                                }}
                            >

                            {({ values, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit} encType="multipart/form-data">
                                    <div id="preview-foto">
                                        <h6 align="center">Preview Foto</h6>

                                        {fileDataUrl ?
                                        <>
                                            <center><img src={fileDataUrl} alt="Preview Img" className="img-fluid" style={{height: '200px', borderRadius: '50%'}} /></center>

                                            {loadingPhoto ?
                                            <center><button className="btn btn-primary btn-sm mt-3" disabled>Loading...</button></center>
                                            :
                                            <center><button type="submit" className="btn btn-primary btn-sm mt-3">Simpan Foto</button></center>
                                            }
                                        </>
                                        :
                                        <div className="text-center">Belum ada foto yang di pilih</div>
                                        }
                                    </div>

                                    <div id="upload-file">
                                        <center>
                                            <input 
                                                type="file" 
                                                id="actual-btn"
                                                name="foto"
                                                hidden
                                                accept='.png, .jpg, .jpeg'
                                                onChange={(e) => {
                                                    const file = e.target.files[0]
                                                    
                                                    if(!file.type.match(imageMimeType)){
                                                        alert('gambar tidak valid');
                                                        return;
                                                    }

                                                    setFile(file)
                                                    setFieldValue('foto', e.currentTarget.files[0])
                                                }}
                                            />
                                            <label htmlFor="actual-btn" style={styleLabel}><FontAwesomeIcon icon={faUpload} /> {fileDataUrl ? 'Ganti Foto' : 'Pilih Foto'}</label>
                                        </center>
                                    </div>
                                </form>
                            )}

                            </Formik>
                            
                        </Tab>

                        {/* Profil Lengkap */}
                        <Tab eventKey="profile" title="Informasi Lainnya">

                            {/* Edit Informasi */}
                            <h4>Edit Informasi</h4>
                            <div>Edit informasi profil Anda lebih detail</div><hr/>

                            <EditInformasi data={defaultData}/>
                        </Tab>

                        <Tab eventKey="change-pass" title="Ganti Password">

                            {/* Ganti Password */}
                            <h4>Ganti Password</h4>
                            <div>Ganti password Anda</div><hr/>

                            <ChangePass idUser={defaultData.idUser} /><hr/>
                        </Tab>
                    </Tabs>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalSettings;