import { Card, Col, Row, Badge } from "react-bootstrap";
import ImgUser from '../user.png';
import { url_static } from "../../../config";

const cardStyle = {
    borderRadius: '20px',
    padding: '20px',
    borderColor: 'red',
}

const CardPendonor = ({data}) => {

    return (
        <>
            <Card style={cardStyle}>
                <Row>
                    <Col md={4}>
                        {data.foto === "" ? 
                        <center><img src={ImgUser} alt="Img User" className="img-fluid" style={{height: '80px', borderRadius: '50%'}} /></center> 
                        : 
                        <img src={`${url_static}/img-pendonor/${data.foto}`} className="img-fluid" alt="Img User" style={{height: '80px', borderRadius: '50%'}} />
                        }
                        
                    </Col>
                    <Col md={8}>
                        <h4>{data.user.firstname} {data.user.lastname}</h4>
                        <div id="detail">
                            <div>{data.jenis_kelamin}</div>
                            <div className="mt-2"><h5><Badge bg="primary">Gol Darah. <b>{ data.gol_darah === 'APLUS' ? 'A+' : data.gol_darah === 'ABPLUS' ? 'AB+' : data.gol_darah === 'OPLUS' ? 'O+' : data.gol_darah }</b></Badge></h5></div>
                        </div>
                    </Col>
                </Row><hr/>

                <h5>Alamat Detail</h5>
                <div>{data.alamat === "" ? "Belum memiliki alanat" : data.alamat}</div>
            </Card>
        </>
    )
}

export default CardPendonor;