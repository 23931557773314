import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import { useEffect, useMemo, useState } from 'react';
import axios from "axios";
import { url_api, url_static } from "../../config";
import imgUser from './images/user.png';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Maps = () => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyC_ZBU3CT-jrO5w_bnT1Au9tnK5RYNSpgQ'
    })
    const center = useMemo(() => ({ lat: 1.474830, lng: 124.842079 }), [])
    const [latLng, setLatLang] = useState([])
    const [activeMarker, setActiveMarker] = useState(null)

    const handleActiveMarker = (marker) => {
        if(marker === activeMarker) return;

        setActiveMarker(marker)
    }

    const _fetchData = async () => {
        try{
            const res = await axios.get(`${url_api}/pendonor/lat-lng`)
            setLatLang(res.data.result)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchData()
    }, [])

    return (
        <>
            {!isLoaded ?

            (<h1>Loading...</h1>)

            : (
                <GoogleMap
                    mapContainerClassName="map-container"
                    center={center}
                    zoom={12}
                    onClick={() => setActiveMarker(null)}
                >
                    {latLng.map((val, key) => {
                        return(
                            <div key={key}>
                                <Marker 
                                    key={key}
                                    position={{ lat: parseFloat(val.latitude), lng: parseFloat(val.longtitude) }}
                                    onClick={() => handleActiveMarker(key)}
                                >
                                    {activeMarker === key ? (
                                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                            <div id='detail'>
                                                <center>
                                                    {val.foto === '' ?
                                                    <img src={imgUser} className="img-fluid" alt="Img User" style={{height: '80px', borderRadius: '50%'}} />
                                                    :
                                                    <img src={`${url_static}/img-pendonor/${val.foto}`} className="img-fluid" alt="Img User" style={{height: '80px', borderRadius: '50%'}} />
                                                    }
                                                </center>

                                                <h4 className='mt-2 text-center'>{val.user.firstname} {val.user.lastname} {val.status_kartu === 'yes' ? <a href="#s" title='Telah memiliki Kartu Pendonor'><FontAwesomeIcon color='#3da9fa' icon={faCheckCircle}/></a> : null} </h4>
                                                <h5 className='text-center'>Gol. Darah <Badge>{ val.gol_darah === 'APLUS' ? 'A+' : val.gol_darah === 'ABPLUS' ? 'AB+' : val.gol_darah === 'OPLUS' ? 'O+'
                                                : val.gol_darah }</Badge></h5><hr/>

                                                <div>Jenis Kelamin : {val.jenis_kelamin}</div>
                                                <div>Alamat Detail : {val.alamat === '' ? 'Belum memiliki alamat' : val.alamat}</div>
                                            </div>
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            </div>
                        )
                    })}
                </GoogleMap>
            )
            }
        </>
    )
}

export default Maps;