import {Toast, ToastContainer} from 'react-bootstrap'

export const ToastAlert = ({body, onShow, onClose = () => {}, variant, containerPosition = '', position}) => {
    return(
        <ToastContainer position={position} className="p-2" containerPosition={containerPosition === '' ? 'absolute' : 'fixed'} >
            <Toast onClose={() => onClose()} show={onShow} delay={3000} bg={variant} autohide>
                <Toast.Body className={`${variant} text-white`}>
                    {body}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}