import {Row, Col, Card} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import './otp.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { url_api } from '../../config'
import { ToastAlert } from '../../components'
import { useHistory } from 'react-router-dom'

const Otp = () => {
    const [otpPertama, setOtpPertama] = useState(0)
    const [otpKedua, setOtpKedua] = useState(0)
    const [otpKetiga, setOtpKetiga] = useState(0)
    const [otpKeempat, setOtpKeempat] = useState(0)
    const [otpKelima, setOtpKelima] = useState(0)
    const [otpKeenam, setOtpKeenam] = useState(0)

    const [showToast, setShowToast] = useState(false)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState(false)

    const history = useHistory()

    const [loading, setLoading] = useState(false)

    const _verifyCode = async () => {
        setTimeout(async () => {
            try{
                const res = await axios.get(`${url_api}/auth/otp/verify-code`, {
                    params: {
                        phonenumber: `${sessionStorage.getItem('no_hp')}`,
                        code: `${otpPertama}${otpKedua}${otpKetiga}${otpKeempat}${otpKelima}${otpKeenam}`
                    }
                })

                if(res.data.success){ // jika kode otp berhasil di verif
                    // buat dan hapus cookies
                    sessionStorage.removeItem('no_hp')
                    sessionStorage.removeItem('nextStep')
                    sessionStorage.setItem('token', res.data.token)

                    sessionStorage.setItem('role', 'pencari')
                    history.push('/pencari')
                }
            }catch(err){
                setShowToast(true)
                setMessage(err.response.data.message)
                setSuccess(err.response.data.success)
            }

            setLoading(false)
        }, 2000)

        setLoading(true)
    }

    // mengirim kode otp ke sms
    const _getCode = async (nomor_hp) => {
        await axios.get(`${url_api}/auth/otp/get-code`, {
            params: {
                phonenumber: `+62${nomor_hp}`,
                channel: 'sms'
            }
        })
        .then(data => console.log(data))
        .catch(err => console.log(err.status))
    }

    useEffect(() => {
        const inputs = document.querySelectorAll('input');
        const button = document.querySelector('button');

        inputs.forEach((input, index) => {
            input.addEventListener('keyup', (e) => {
                const currentInput = input;
                const nextInput = input.nextElementSibling;
                const prevInput = input.previousElementSibling;

                if(currentInput.value.length > 1){
                    currentInput.value = "";
                    return;
                }

                if(nextInput && nextInput.hasAttribute('disabled') && currentInput.value !== ""){
                    nextInput.removeAttribute('disabled');
                    nextInput.focus();
                }

                if(e.key === "Backspace"){
                    inputs.forEach((input, i) => {
                        if(index <= i && prevInput){
                            input.setAttribute('disabled', true);
                            input.value = "";
                            prevInput.focus();
                        }
                    })
                }

                setOtpPertama(inputs[0].value)
                setOtpKedua(inputs[1].value)
                setOtpKetiga(inputs[2].value)
                setOtpKeempat(inputs[3].value)
                setOtpKelima(inputs[4].value)
                setOtpKeenam(inputs[5].value)

                if(!inputs[5].disabled && inputs[5].value !== ''){
                    button.classList.add("active");
                    return;
                }

                button.classList.remove("active")

            })
        })


    }, [])

    return(
        <>
            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Row className='justify-content-md-center'>
                <Col md={5}>
                    <Card style={{
                        padding: '20px',
                        borderRadius: '30px'
                    }}>
                        <h3 align="center" style={{color: '#ff2323'}}>Pendonor Sukarela</h3><hr/>

                        <FontAwesomeIcon icon={faCheckCircle} className="fa-4x" style={{color: '#fc7c72'}} /><br/>

                        <h4 align="center">Masukkan Kode OTP</h4>
                        <p align="center">Kode OTP telah terkirim ke nomor Anda</p>

                        <form className='form-otp'>
                            <div className='input-otp'>
                                <input type="number" />
                                <input type="number" disabled/>
                                <input type="number" disabled />
                                <input type="number" disabled />
                                <input type="number" disabled />
                                <input type="number" disabled />
                            </div><br/>

                            {loading ?
                            <button id="btn-otp" disabled>Loading...</button>
                            :
                            <button id="btn-otp" onClick={() => _verifyCode()}>Verifikasi OTP</button>
                            }
                        </form><hr/>

                        <div className='text-center'>Belum terkirim kode OTP nya? <a href="#send" onClick={() => _getCode(sessionStorage.getItem('no_hp'))} style={{textDecoration: 'none'}}>Kirim Lagi</a></div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Otp;