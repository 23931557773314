import { Card, Col, Container, Row } from "react-bootstrap";
import { MenuAdmin } from "../../components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import ChangeProfil from "./ChangeProfil";
import ChangePassword from "./ChangePassword";

const cardStyle = {
    borderRadius: '50px',
    padding: '40px',
}

const Layout = ({user}) => {
    return(
        <>
            <MenuAdmin user={user} />

            <Container className="mt-5">
                <Card style={cardStyle}>
                    <h2>Profil</h2><hr/>

                    <Row className="mt-3">
                        <Col md={6}>
                            <h4>Ganti Password</h4><br/>

                            <ChangePassword user={user} />
                        </Col>
                        <Col md={6}>
                            <center><FontAwesomeIcon icon={faUserTie} className="fa-8x" style={{color: '#fc7c72'}} /></center>
                            <h4 className="mt-3 text-center">{user.firstname} {user.lastname}</h4>
                            <div className="text-center">{user.email}</div><hr/>

                            <ChangeProfil user={user} />
                        </Col>
                    </Row>
                </Card>
            </Container><br/>
        </>
    )
}

export default Layout;