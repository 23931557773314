import { Formik } from "formik";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { url_api } from "../../config";

const ChangePassword = ({user}) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [message, setMessage] = useState('')

    const _savePassword = async (data) => {
        try{
            const res = await axios.post(`${url_api}/profil/change-pass/${user.id}`, {
                old_pass: data.old_pass,
                new_pass: data.new_pass,
                reply_new_pass: data.reply_new_pass
            }, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setSuccess(res.data.success)
            setMessage(res.data.message)
            setLoading(false)
        }catch(err){
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>
            <Formik
                initialValues={{
                    old_pass: '',
                    new_pass: '',
                    reply_new_pass: ''
                }}
                onSubmit={(values) => {
                    setTimeout(() => {
                        _savePassword(values)
                    }, 1000)

                    setLoading(true)
                }}
            >

            {({values, errors, touched, handleSubmit, handleChange}) => (
                <form onSubmit={handleSubmit}>

                    {success === true ?
                    <div className='alert alert-success'>{message}</div>
                    : success === false ?
                    <div className='alert alert-danger'>{message}</div>
                    :
                    null
                    }

                    <div className="mb-3">
                        <label htmlFor="old_pass"><b>Password Lama</b></label>
                        <input
                            type="password"
                            id="old_pass"
                            name="old_pass"
                            className="form-control mt-2"
                            placeholder="Password Lama"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="new_pass"><b>Password Baru</b></label>
                        <input
                            type="password"
                            id="new_pass"
                            name="new_pass"
                            className="form-control mt-2"
                            placeholder="Password Baru"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="reply_new_pass"><b>Ulangi Password Baru</b></label>
                        <input
                            type="password"
                            id="reply_new_pass"
                            name="reply_new_pass"
                            className="form-control mt-2"
                            placeholder="Ulangi Password Baru"
                            onChange={handleChange}
                        />
                    </div>
                    {loading ?
                    <button className='btn btn-outline-danger disabled'>Loading... <Spinner size='sm' /></button>
                    :
                    <button type="submit" className='btn btn-outline-danger'>Ganti Password</button>
                    }
                </form>
            )}

            </Formik>
        </>
    )
}

export default ChangePassword;