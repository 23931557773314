import { Formik } from 'formik'
import { Col, Container, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import axios from 'axios';
import { ToastAlert } from '../../components';
import { url_api } from '../../config';
import { useHistory } from 'react-router-dom'
import imgPendonor from './donate-blood.jpg';

// Styles
const cardRegis = {
    borderRadius: '20px',
    borderColor: '#ff2323',
    padding: '30px'
}

const Layout = () => {
    const history = useHistory()
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get('id')
    const permintaan = queryParameters.get('permintaan')

    const _handleLogin = async (data) => {
        try{
            const res = await axios.post(`${url_api}/auth/login`, {
                no_hp: data.no_hp,
                password: data.password,
                role: 'pendonor'
            })

            if(res.data.success){
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('role', res.data.role)

                if(permintaan === "yes"){
                    history.push(`/detail-permintaan?id=${id}`) // redirect
                }else{
                    history.push(`/${res.data.role}`) // redirect
                }
            }
        }catch(err){
            setShowToast(true)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>

            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Container className='mt-5 pb-5'>
                <Row className='justify-content-md-center'>
                    <Col md={6}>
                        <Card style={cardRegis}>
                            <Card.Body>
                                <h3 align="center" style={{color: '#ff2323'}}>Pendonor Sukarela</h3><hr/>

                                <center><img src={imgPendonor} alt="img pendonor" className='img-fluid' style={{height: '200px'}}/></center><br/>

                                <p>Login untuk melihat siapa saja yang membutuhkan darah Anda untuk didonorkan kepada mereka yang membutuhkan</p>

                                <Formik
                                    initialValues={{
                                        no_hp: '',
                                        password: ''
                                    }}
                                    onSubmit={(values) => {
                                        setTimeout(() => {
                                            _handleLogin(values)
                                            setLoading(false)
                                        }, 1000)

                                        setLoading(true)
                                    }}
                                >

                                    {({values, errors, touched, handleSubmit, handleChange}) => (
                                        
                                        <form onSubmit={handleSubmit} className="mt-3">

                                            <div className='mb-2'>
                                                <label htmlFor='no_hp' className='mb-2'><b>Nomor HP</b></label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon1">+62</span>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id='no_hp' 
                                                        name='no_hp' 
                                                        placeholder="Nomor HP"
                                                        onChange={handleChange} 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-2'>
                                                <label htmlFor='password' className='mb-2'><b>Password</b></label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faLock} /></span>
                                                    <input 
                                                        type="password" 
                                                        className="form-control" 
                                                        id='password' 
                                                        name='password' 
                                                        placeholder="Password"
                                                        onChange={handleChange} 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            <Row className='mt-4'>
                                                <Col md={5}>
                                                    {loading ?
                                                    <button className='btn btn-outline-danger w-100' disabled>Loading...</button>
                                                    :
                                                    <button type='submit' className='btn btn-outline-danger w-100'>Login</button>
                                                    }
                                                </Col>
                                            </Row>
                                        </form>

                                    )}

                                </Formik>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <div align="center" className='mt-3'>Tidak punya akses? <a href='/' style={{textDecoration: 'none'}}>Kembali</a></div>
            </Container>
        </>
    )
}

export default Layout;