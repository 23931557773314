import Helmet from 'react-helmet'
import Layout from './Layout';
import { useClient } from '../../components';

const AdminDetailPendonor = () => {
    const client = useClient()

    return(
        <>
            <Helmet>
                <title>Detail Pendonor - Admin</title>
            </Helmet>

            <Layout user={client.account}/>
        </>
    )
}

export default AdminDetailPendonor;