import { Card, Container, Row, Table, Col } from "react-bootstrap";
import { MenuPendonor } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { url_api } from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from "react-paginate";
import moment from "moment";
import ModalDelete from "./ModalDelete";

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'blue',
    padding: '20px'
}

const Layout = ({data}) => {
    const [dataPengajuan, setDataPengajuan] = useState([])
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [message, setMessage] = useState("")

    const [idPengajuan, setIdPengajuan] = useState(0)
    const [showModalDelete, setShowModalDelete] = useState(false)

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const _fetchData = async () => {
        try{
            const res = await axios.get(`${url_api}/mendonor/data-pengajuan/${data.detailUser.id}?page=${page}&limit=${limit}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setDataPengajuan(res.data.result)
            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, page, limit])

    return(
        <>
            <MenuPendonor data={data} />

            <ModalDelete 
                data={{
                    idPengajuan: idPengajuan
                }}
                show={showModalDelete}
                onClose={() => setShowModalDelete(false)}
            />

            <Container className="mt-5 pb-4">
                <Card style={cardStyle}>
                    <h3>Data Pengajuan</h3>
                    <div>Dibawah ini adalah data pengajuan Anda yang telah dikirim ke beberapa permintaan</div>

                    <div className="alert alert-primary mt-2">
                        <li>Apabila sudah berwarna hijau, maka Anda telah melakukan pendonoran darah pada pengajuan yang Anda buat</li>
                    </div><hr/>

                    <Row>
                        <Col md={4}>
                            <select
                                name="limit"
                                id="limit"
                                className="form-select"
                                onChange={(e) => {
                                    setLimit(e.target.value)
                                }}
                            >
                                <option value="">Limit Data</option>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={150}>150</option>
                                <option value={200}>200</option>
                            </select>
                        </Col>
                    </Row>

                    <Table style={{marginTop: '2%'}} responsive={true} striped bordered>
                        <thead>
                            <tr>
                                <th>Nama Pencari</th>
                                <th>Tgl Pengajuan</th>
                                <th>Tgl Permintaan</th>
                                <th>Tgl Mendonor</th>
                                <th>Status</th>
                                <th>Keterangan</th>
                                <th>Opsi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataPengajuan.length > 0 ?
                            dataPengajuan.map((val, key) => {
                                console.log(val.done)
                                return (
                                    <tr key={key} style={{backgroundColor: val.done === "yes" ? "#8fff5f" : null }}>
                                        <td>{val.permintaan_mendonor.fullname_pencari}</td>
                                        <td>{moment(val.tgl_pengajuan).format('DD/MM/YYYY')}</td>
                                        <td>{moment(val.permintaan_mendonor.tgl_permintaan).format('DD/MM/YYYY')}</td>
                                        <td>{moment(val.permintaan_mendonor.tgl_mendonor).format('DD/MM/YYYY')}</td>
                                        <td>{val.status === 'diterima' ? <div><font color="green">Diterima.</font></div> : 'Belum Diterima'}</td>
                                        <td>{val.permintaan_mendonor.keterangan}</td>
                                        <td>
                                            {val.status === 'belum diterima' ? 
                                            <button className="btn btn-danger btn-sm" onClick={() => {
                                                setIdPengajuan(val.id)
                                                setShowModalDelete(true)
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                            : '-'}
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={8} align="center">Belum ada data</td>
                            </tr>
                            }
                        </tbody>
                    </Table>

                    <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                    <div><font color="red">{message}</font></div>

                    {dataPengajuan.length > 0 ? 
                    <nav key={rows} style={{marginTop: '1%'}}>
                        <ReactPaginate
                            previousLabel={"< Previous"}
                            nextLabel={"Next >"}
                            pageCount={Math.min(10, pages)}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeLinkClassName={"page-item active"}
                            disabledLinkClassName={"page-item disabled"}
                        />
                    </nav>
                    : null}

                </Card>
            </Container>
        </>
    )
}

export default Layout;