import { Card } from "react-bootstrap";
import moment from 'moment';
import {Link} from 'react-router-dom';

const CardPermintaan = ({data}) => {
    return(
        <>
            <Card className="shadow">
                <Card.Body>
                    
                    <table width='100%'>
                        <tbody>
                            <tr>
                                <td>Nama Pencari</td>
                                <td>:</td>
                                <td>{data.fullname_pencari}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Permintaan</td>
                                <td>:</td>
                                <td>{moment(data.tgl_permintaan).format('MM/DD/YYYY')}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Mendonor</td>
                                <td>:</td>
                                <td>{moment(data.tgl_mendonor).format('MM/DD/YYYY')}</td>
                            </tr>
                            <tr>
                                <td>Gol Darah</td>
                                <td>:</td>
                                <td>{ data.gol_darah === 'APLUS' ? 'A+' : data.gol_darah === 'ABPLUS' ? 'AB+' : data.gol_darah === 'OPLUS' ? 'O+' : data.gol_darah }</td>
                            </tr>
                            <tr>
                                <td>Jumlah Kantong Darah</td>
                                <td>:</td>
                                <td><b>{data.kantong_darah}</b></td>
                            </tr>
                        </tbody>
                    </table><hr/>

                    <Link to={`/detail-permintaan/${data.id}`} className="btn btn-primary w-100">Lihat Pengajuan</Link>

                </Card.Body>
            </Card>
        </>
    )
}

export default CardPermintaan;