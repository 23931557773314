import { Card, } from 'react-bootstrap';
import imgUser from './user.png';
import { url_static } from '../../config';
import { useState } from 'react';
import ModalSelesaiMendonor from './ModalSelesaiMendonor';

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'blue',
    padding: '20px'
}

const CardPendonor = ({data, dataPendonor}) => {

    const [showModal, setShowModal] = useState(false)
    const _handleShow = () => setShowModal(true)
    const _handleClose = () => setShowModal(false)

    return(
        <>

            <ModalSelesaiMendonor 
                show={showModal} 
                onClose={_handleClose}
                data={{
                    nama: data.fullname_pendonor,
                    idPengajuan: data.id,
                    idPermintaan: data.permintaanMendonorId
                }}
            />

            <Card style={cardStyle}>
                <center>
                    {dataPendonor.foto === '' ?
                    <img src={imgUser} className="img-fluid" alt="Img User" style={{height: '140px', borderRadius: '50%'}} />
                    :
                    <img src={`${url_static}/img-pendonor/${dataPendonor.foto}`} className="img-fluid" alt="Img User" style={{height: '140px', borderRadius: '50%'}} />
                    }
                </center>

                <h4 className='mt-3 text-center'>{data.fullname_pendonor}</h4>
                <h5 align="center">+62 {data.nohp_pendonor}</h5><hr/>

                <div>Email : {data.email_pendonor}</div>
                <div>Jenis Kelamin : {dataPendonor.jenis_kelamin}</div>
                <h5 className='mt-2'><b>{dataPendonor.alamat}</b></h5><hr/>
                
                <button className='btn btn-success w-100' onClick={() => _handleShow()}>Selesai Mendonor</button>

            </Card>
        </>
    )
}

export default CardPendonor;