import {Col, Container, Row} from 'react-bootstrap'
import { MenuPMI } from '../../components/MenuPMI';
import CardPermintaan from './CardPermintaan';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { url_api } from '../../config';
import ReactPaginate from 'react-paginate';

const Layout = ({data}) => {
    const [permintaan, setPermintaan] = useState([])
    const [limit, setLimit] = useState(0)
    const [page, setPage] = useState(0)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [keyword, setKeyword] = useState("")
    const [query, setQuery] = useState("")
    const [message, setMessage] = useState("")

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setMessage("");
        setKeyword(query);
    }

    const _fetchData = async () => {
        try{
            const res = await axios.get(`${url_api}/permintaan?search=${keyword}&page=${page}&limit=${limit}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPermintaan(res.data.result)
            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, keyword, limit])

    return(
        <>
            <MenuPMI data={data}/>

            <Container className='mt-5'>
                <h3>Selamat Datang, {data.user.firstname} {data.user.lastname} SULUT</h3>
                <div>Silahkan untuk kelola sistem ini</div><hr/>

                <h5>Data Permintaan</h5>
                <div>Dibawah ini merupakan data permintaan yang di kirim oleh pencari kantong darah</div><br/>

                <form onSubmit={searchData}>
                    <Row>
                        <Col md={4}>
                            <input 
                                type="text"
                                className="form-control"
                                value={query}
                                onChange={(e) => setQuery(e.target.value) }
                                placeholder="Nama Pencari..."
                            />
                        </Col>
                        <Col md={2}>
                            <button type='submit' className="btn btn-primary w-100">Cari...</button>
                        <br/></Col>
                    </Row>
                </form>

                {permintaan.length > 0 ?
                <Row className='mt-3'>
                    {permintaan.map((val, key) => {
                        return (
                            <Col md={4} key={key}>
                                <CardPermintaan 
                                    data={val}
                                />
                            <br/></Col>
                        )
                    })}
                </Row>

                : 
                <div className='mt-3 mb-3'>
                    <h4>Pencari belum membuat Permintaan Mendonor</h4>
                </div>
                }

                <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                <div><font color="red">{message}</font></div>

                {permintaan.length > 0 ? 
                <nav key={rows} style={{marginTop: '1%'}}>
                    <ReactPaginate
                        previousLabel={"< Previous"}
                        nextLabel={"Next >"}
                        pageCount={Math.min(10, pages)}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        activeLinkClassName={"page-item active"}
                        disabledLinkClassName={"page-item disabled"}
                    />
                </nav>
                : null}

            </Container>
        </>
    )
}

export default Layout;