import {GoogleMap, useLoadScript, Marker} from '@react-google-maps/api'
import { useMemo } from 'react'

const Maps = ({latitude, longtitude, alamat}) => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyC_ZBU3CT-jrO5w_bnT1Au9tnK5RYNSpgQ'
    })
    const center = useMemo(() => ({ 
        lat: parseFloat(latitude), 
        lng: parseFloat(longtitude) 
    }), [latitude, longtitude])

    return(
        <>
            {!isLoaded ?

            (<h5>Mohon Menunggu...</h5>)

            : (
                <GoogleMap
                    mapContainerStyle={{
                        height: '250px',
                        width: '100%'
                    }}
                    center={center}
                    zoom={8}
                >

                    <Marker position={center} />

                </GoogleMap>
            )}

            <hr/><h4 className='mt-3'>Alamat</h4>
            <div>{alamat === '' ? 'Belum memiliki alamat detail' : alamat}</div>
        </>
    )
}

export default Maps;