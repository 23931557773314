import Helmet from 'react-helmet'
import Layout from './Layout';
import { useClient } from '../../components';

const ProfilAdmin = () => {
    const client = useClient()

    return(
        <>
            <Helmet>
                <title>Profil</title>
            </Helmet>

            <Layout user={client.account}/>
        </>
    )
}

export default ProfilAdmin;