import {GoogleMap, useLoadScript, Marker} from '@react-google-maps/api'
import { useMemo } from 'react'

const Maps = ({latitude, longtitude}) => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyC_ZBU3CT-jrO5w_bnT1Au9tnK5RYNSpgQ'
    })
    const center = useMemo(() => ({ 
        lat: parseInt(latitude), 
        lng: parseInt(longtitude) 
    }), [latitude, longtitude])

    return(
        <>
            {!isLoaded ?

            (<h5>Mohon Menunggu...</h5>)

            : (
                <GoogleMap
                    mapContainerStyle={{
                        height: '200px',
                        width: '100%'
                    }}
                    center={center}
                    zoom={8}
                >

                    <Marker position={{lat: parseInt(latitude), lng: parseInt(longtitude)}} />

                </GoogleMap>
            )}
        </>
    )
}

export default Maps;