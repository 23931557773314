import Helmet from 'react-helmet'
import Layout from './Layout';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const RegisterPendonor = () => {
    const history = useHistory()

    useEffect(() => {
        // jika sudah login, tidak bisa kesini
        if(sessionStorage.getItem('token') !== null){
            history.push(`/${sessionStorage.getItem('role')}`)
        }
    }, [history])

    return(
        <>
            <Helmet>
                <title>Daftar Sekarang | Pendonor Sukarela</title>
            </Helmet>

            <Layout />
        </>
    )
}

export default RegisterPendonor;