import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { MenuPendonor, ToastAlert } from "../../components";
import axios from "axios";
import { url_api, url_static, getCurrentDate } from "../../config";
import { useEffect, useState } from "react";
import imgUser from './user.png';
import moment from 'moment';

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'blue',
    padding: '20px'
}

const Layout = ({data}) => {
    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get('id')

    const [permintaan, setPermintaan] = useState([])
    const [detailPermintaan, setDetailPermintaan] = useState([])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState('')
    const [showToast, setShowToast] = useState(false)

    const _fetchPermintaan = async () => {
        try{
            const res = await axios.get(`${url_api}/permintaan/${id}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setDetailPermintaan(res.data.result[0].pencari)
            setPermintaan(res.data.result[0])
        }catch(err){
            console.log(err)
        }
    }

    const _sendPengajuan = async (data) => {
        try{
            const res = await axios.post(`${url_api}/mendonor/send-pengajuan`, {
                alamatPendonor: data.alamatPendonor,
                permintaanMendonorId: data.permintaanMendonorId,
                pencariId: data.pencariId,
                pendonorId: data.pendonorId,
                fullnamePendonor: data.fullnamePendonor,
                noHpPendonor: data.noHpPendonor,
                emailPendonor: data.emailPendonor,
                tglPengajuan: getCurrentDate()
            }, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            if(res.data.success){
                setShowToast(true)
                setMessage(res.data.message)
                setSuccess(res.data.success)
                setLoading(false)
            }
        }catch(err){
            setShowToast(true)
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    useEffect(() => {
        _fetchPermintaan()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <>
            <MenuPendonor data={data} />

            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Container className="mt-5 pb-5">
                <Row className="justify-content-md-center">
                    <Col md={6}>

                        {data.detailUser.gol_darah !== permintaan.gol_darah ?
                        <div className="alert alert-danger">Maaf, permintaan ini tidak bisa Anda bantu karena gol darah Anda tidak sesuai dengan permintaan golongan darah yang dibutuhkan</div>
                        : null}

                        <Card style={cardStyle}>
                            <Card.Body>
                                <center>
                                    {detailPermintaan.foto === '' ?
                                    <img src={imgUser} className="img-fluid" alt="Img User" style={{height: '100px', borderRadius: '50%'}} />
                                    :
                                    <img src={`${url_static}/img-pencari/${detailPermintaan.foto}`} className="img-fluid" alt="Img User" style={{height: '100px', borderRadius: '50%'}} />
                                    }
                                </center>

                                <h4 className="mt-3 text-center">{permintaan.fullname_pencari}</h4>
                                <h5 className="text-center">{detailPermintaan.alamat === '' ? 'Belum memiliki alamat' : detailPermintaan.alamat}</h5><hr/>

                                <div id="data-permintaan">
                                    <div>Tanggal Permintaan : <b>{moment(permintaan.tgl_permintaan).format("DD/MM/YYYY")}</b></div>
                                    <div>Tanggal Mulai Mendonor : <b>{moment(permintaan.tgl_mendonor).format("DD/MM/YYYY")}</b></div>
                                    <div>Gol Darah yang dicari : <b>{permintaan.gol_darah === 'APLUS' ? 'A+' : permintaan.gol_darah === 'ABPLUS' ? 'AB+' : permintaan.gol_darah === 'OPLUS' ? 'O+'
                                    : permintaan.gol_darah}</b></div><hr/>

                                    <h5>Keterangan</h5>
                                    <div>{permintaan.keterangan}</div><hr/>

                                    <Row>
                                        <Col md={6}>
                                            {loading ?
                                            <button className="mt-3 btn btn-primary w-100" disabled>Loading... <Spinner size="sm" /> </button>
                                            :
                                            
                                            <button className="mt-3 btn btn-primary w-100" onClick={() => {
                                                setTimeout(() => {
                                                    _sendPengajuan({
                                                        alamatPendonor: data.detailUser.alamat,
                                                        permintaanMendonorId: permintaan.id,
                                                        pencariId: permintaan.pencariId,
                                                        pendonorId: data.detailUser.id,
                                                        fullnamePendonor: data.user.firstname + " " + data.user.lastname,
                                                        noHpPendonor: data.user.no_hp,
                                                        emailPendonor: data.user.email
                                                    })
                                                }, 1000)

                                                setLoading(true)
                                            }} disabled={data.detailUser.gol_darah === permintaan.gol_darah ? false : true}>Bantu Sekarang</button>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Layout;