import { Spinner, Row, Col, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import {Redirect, Route} from 'react-router-dom'
import {useClient} from './client'

export const PrivateRoute = ({...props}) => {
    const client = useClient()
    const [isAuth, setIsAuth] = useState(null)

    useEffect(() => {
      const fetch = async () => {
        const authenticated = await client.authenticate()
        
        // jika belum login
        if(authenticated === undefined){
            setIsAuth(false)
        }else{ // jika sudah login
            setIsAuth(true)
        }
      }

      fetch()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(isAuth === null) return (
      <Container style={{marginTop: '10%'}}>
        <Row className="justify-content-center">
            <Col md={4}>
              <center>
                <Spinner animation="border">
                    <span className="visually-hidden">Loading...</span>
                </Spinner><br />

                <div style={{marginTop: '4%'}}>Loading...</div>
              </center>
            </Col>
        </Row>
      </Container>
    )

    if (isAuth === false) return (<Redirect to="/" />)
    
    return (<Route {...props} />)
}