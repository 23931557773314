import { useParams } from "react-router-dom";
import { MenuPMI } from "../../components/MenuPMI";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import axios from "axios";
import { url_api } from "../../config";
import { useEffect, useState } from "react";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import CardPendonor from "./CardPendonor";

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'red',
    padding: '20px'
}

const Layout = ({data}) => {
    const { idPermintaan } = useParams()
    const [permintaan, setPermintaan] = useState([])

    // pengajuan
    const [pengajuan, setPengajuan] = useState([])
    const [limit, setLimit] = useState(0)
    const [page, setPage] = useState(0)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [keyword, setKeyword] = useState("")
    const [query, setQuery] = useState("")
    const [message, setMessage] = useState("")

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setMessage("");
        setKeyword(query);
    }

    const _fetchPermintaan = async () => {
        try{
            const res = await axios.get(`${url_api}/permintaan/${idPermintaan}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPermintaan(res.data.result[0])
        }catch(err){
            console.log(err)
        }
    }

    const _fetchPengajuan = async () => {
        try{
            const res = await axios.get(`${url_api}/pengajuan?search=${keyword}&page=${page}&limit=${limit}&idPermintaan=${idPermintaan}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPengajuan(res.data.result)
            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchPermintaan()
        _fetchPengajuan()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPermintaan, page, keyword, limit])

    return(
        <>
            <MenuPMI data={data} />

            <Container className="mt-5">
                
                <Row>
                    <Col md={5}>
                        <Card style={cardStyle}>
                            <h4>Data Permintaan Mendonor</h4>
                            <div>Dibawah ini adalah data detail mengenai permintaan Anda ke Pendonor</div><hr/>

                            {permintaan === undefined ? null
                            :
                            <>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td>Nama Pencari</td>
                                            <td>:</td>
                                            <td><b>{permintaan.fullname_pencari}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal Permintaan</td>
                                            <td>:</td>
                                            <td>{moment(permintaan.tgl_permintaan).format("DD/MM/YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal Mulai Mendonor</td>
                                            <td>:</td>
                                            <td>{moment(permintaan.tgl_mendonor).format("DD/MM/YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <td>Kantong Darah</td>
                                            <td>:</td>
                                            <td><b>{permintaan.kantong_darah}</b></td>
                                        </tr>
                                    </tbody>
                                </table><hr/>

                                <h5>Golongan Darah Yang Dicari : <Badge bg="primary">{permintaan.gol_darah === 'APLUS' ? 'A+' : permintaan.gol_darah === 'ABPLUS' ? 'AB+' : permintaan.gol_darah === 'OPLUS' ? 'O+' : permintaan.gol_darah}</Badge></h5><hr/>

                                <h5>Keterangan</h5>
                                <div>{permintaan.keterangan}</div>
                            </>
                            }
                        </Card>
                    </Col>
                    <Col md={7}>
                        <h4>Data Pengajuan</h4>
                        <div>Dibawah ini adalah data pendonor yang melakukan pengajuan pada permintaan ini</div><hr/>

                        <form onSubmit={searchData}>
                            <Row>
                                <Col md={6}>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value) }
                                        placeholder="Nama Pendonor..."
                                    />
                                </Col>
                                <Col md={3}>
                                    <button type='submit' className="btn btn-primary w-100">Cari...</button>
                                <br/></Col>
                            </Row>
                        </form>

                        {pengajuan.length > 0 ?
                        <Row className='mt-3'>
                            {pengajuan.map((val, key) => {
                                return (
                                    <Col md={6} key={key}>
                                        <CardPendonor 
                                            data={val}
                                            dataPendonor={val.pendonor}
                                        />
                                    <br/></Col>
                                )
                            })}
                        </Row>

                        : 
                        <div className='mt-3 mb-3'>
                            <h4>Pendonor yang Anda cari tidak ditemukan</h4>
                            <div>Pendonor tidak ditemukan karena pendonor belum melakukan pengajuan pada permintaan ini</div><hr/>
                        </div>
                        }

                        <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                        <div><font color="red">{message}</font></div>

                        {pengajuan.length > 0 ? 
                        <nav key={rows} style={{marginTop: '1%'}}>
                            <ReactPaginate
                                previousLabel={"< Previous"}
                                nextLabel={"Next >"}
                                pageCount={Math.min(10, pages)}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                pageLinkClassName={"page-link"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                activeLinkClassName={"page-item active"}
                                disabledLinkClassName={"page-item disabled"}
                            />
                        </nav>
                        : null}
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Layout;