import { useEffect, useState } from "react";
import axios from "axios";
import { url_api } from "../../../config";
import moment from "moment";
import 'moment/locale/id';
import KartuPendonor from "./KartuPendonor";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastAlert } from "../../../components";

const DetailInformasi = ({idPendonor, data}) => {
    const [totalData, setTotalData] = useState(0)
    const [tglSelesai, setTglSelesai] = useState("")
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState(false)
    const [showToast, setShowToast] = useState(false)

    // random nomor id kartu
    const numberIdCard = () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
        var charactersLength = characters.length;

        var numberFirst = '1234567890';
        var numberFirstLength = numberFirst.length;
        var resultNumberFirst = '';

        var numberLast = '1234567890';
        var numberLastLength = numberLast.length;
        var resultNumberLast = '';

        for(var i = 0; i < 6; i++){
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        for(var digit = 0; digit < 4; digit++){
            resultNumberFirst += numberFirst.charAt(Math.floor(Math.random() * numberFirstLength));
        }

        for(var digitSecond = 0; digitSecond < 6; digitSecond++){
            resultNumberLast += numberLast.charAt(Math.floor(Math.random() * numberLastLength));
        }


        return `${resultNumberFirst}${result}${resultNumberLast}`;
    }

    const _fetchData = async () => {
        try{
            const res = await axios.get(`${url_api}/mendonor/${idPendonor}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setTotalData(res.data.totalData)
            setTglSelesai(res.data.result[0].tgl_selesai)
        }catch(err){
            console.log(err)
        }
    }

    // buat kartu pendonor
    const _makeCard = async (idPendonor) => {
        try{
            const res = await axios.put(`${url_api}/mendonor/make-card/${idPendonor}`, {
                nomorId: numberIdCard()
            }, { headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`} })

            if(res.data.success){
                setLoading(false)
                setShowToast(true)
                setMessage(res.data.message)
                setSuccess(res.data.success)

                setTimeout(() => {
                    window.location.href = "/profil";
                }, 1500)
            }
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPendonor])

    return(
        <>
            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <div className="alert alert-info">
                <div>1. {totalData === 0 ? `Anda belum mendonor` : `Anda telah mendonor sebanyak ${totalData} kali`}</div>
                <div>2. {totalData > 0 ? `Anda terakhir mendonor pada tanggal ${moment(tglSelesai).format("DD/MM/YYYY")}` : 'Belum ada pendonoran yang telah Anda selesaikan'} </div>
            </div><hr/>

            

            {data.detailUser.status_kartu === 'yes' ? // jika kartu pendonor telah dibuat

            <KartuPendonor data={data}/>

            :
            <>
                <h5>Kartu Pendonor</h5>
            
                {totalData >= 5 ?
                <div id="make-card">
                    <div>Anda sudah mendonor sebanyak 5 kali. Silahkan buat Kartu Pendonor Anda dengan menekan tombol Buat Kartu Sekarang dibawah ini</div>
                    <Row>
                        <Col md={6}>
                            {loading ?
                            <button className="btn btn-danger w-100 mt-3" disabled>Loading <Spinner size="sm"/></button>
                            :
                            <button onClick={() => {
                                setTimeout(() => {
                                    _makeCard(data.detailUser.id)
                                }, 1000)

                                setLoading(true)
                            }} className="btn btn-danger w-100 mt-3">Buat Kartu Sekarang</button>
                            }
                        </Col>
                    </Row>
                </div>
                :
                <div>Anda belum punya Kartu Pendonor karena belum mencapai 5 kali mendonor</div>
                }
            </>
            }
            
        </>
    )
}

export default DetailInformasi;