import { Card, Spinner } from 'react-bootstrap';
import imgUser from './user.png';
import { url_api, url_static } from '../../config';
import { ToastAlert } from '../../components';
import { useState } from 'react';
import axios from 'axios';

const cardStyle = {
    borderRadius: '20px',
    borderColor: 'blue',
    padding: '20px'
}

const CardPendonor = ({permintaanMendonorId, pendonorId, fullname, fotoProfil, alamat}) => {
    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState()
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const _acceptPendonor = async () => {
        try{
            const res = await axios.put(`${url_api}/mendonor/accept-pengajuan`, {
                permintaanMendonorId: permintaanMendonorId,
                pendonorId: pendonorId,
                status: 'diterima'
            }, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            if(res.data.success){
                setMessage(res.data.message)
                setSuccess(res.data.success)
                setShowToast(true)
                setLoading(false)
            }
        }catch(err){
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
            setShowToast(true)
            setLoading(false)
        }
    }

    return(
        <>
            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Card style={cardStyle}>
                <center>
                    {fotoProfil === '' ?
                    <img src={imgUser} className="img-fluid" alt="Img User" style={{height: '140px', borderRadius: '50%'}} />
                    :
                    <img src={`${url_static}/img-pendonor/${fotoProfil}`} className="img-fluid" alt="Img User" style={{height: '140px', borderRadius: '50%'}} />
                    }
                </center>

                <h4 className='mt-3 text-center'>{fullname}</h4>
                <h5 align="center">+62 xxx xxxx xxxx</h5><hr/>

                <div>Email : xxxxx@xxxx.com</div>
                <h5 className='mt-2'><b>{alamat}</b></h5><hr/>

                {loading ?
                <button className='btn btn-primary w-100' disabled>Loading... <Spinner size='sm'/></button>
                :
                <button className='btn btn-primary w-100' onClick={() => {
                    setTimeout(() => {
                        _acceptPendonor()
                    }, 1000)

                    setLoading(true)
                }}>Terima</button>
                }

            </Card>
        </>
    )
}

export default CardPendonor;