import { createContext, useContext, useMemo, useState } from "react";
import axios from 'axios'
import {url_api} from '../config'

export const ClientContext = createContext(null)

const getAccountRole = (role) => {
    if(role === 'pendonor'){
        return 'pendonor'
    }else if(role === 'pencari'){
        return 'pencari'
    }else if(role === 'pmi'){
        return 'pmi'
    }else if(role === 'bank-darah'){
        return 'bank-darah'
    }else{
        return 'admin'
    }
}

const detailAccount = async (account, id_user, role) => {
    if(getAccountRole(role) === 'pendonor'){
        const res = await axios.get(`${url_api}/auth/pendonor/${id_user}`, {headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}})
        account.pendonor = res.data
    }else if(getAccountRole(role) === 'pencari'){
        const res = await axios.get(`${url_api}/auth/pencari/${id_user}`, {headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}})
        account.pencari = res.data
    }

    return account
}

export const ClientProvider = ({children}) => {
    const [roleAccount, setRoleAccount] = useState(null)
    const [account, setAccount] = useState(null)
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const role = useMemo(() => {
        if(roleAccount === null) return null
        return getAccountRole(roleAccount)
    }, [roleAccount])

    const authenticate = async () => {
        try{
            const res = await axios.get(`${url_api}/auth`, {headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}})

            if(res.data.role === 'pendonor'){
                const account = detailAccount(res.data, res.data.id, res.data.role)
                setAccount(account)
                // setAccount(res.data)
            }else if(res.data.role === 'pencari'){
                const account = detailAccount(res.data, res.data.id, res.data.role)
                setAccount(account)
            }else{
                setAccount(res.data)
            }

            setRoleAccount(res.data.role)
            setIsAuthenticated(true)

            return res.data
        }catch(err) {
            // jika token sudah habis waktu
            // token pada browser akan terhapus
            if(err.response.data.loggedIn === false){
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('role')
                localStorage.removeItem('idPermintaan')
            }
        }
    }

    const logout = async () => {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('role')
        localStorage.removeItem('idPermintaan')

        setIsAuthenticated(false)

        // redirect
        window.location = "/"
    }

    const client = useMemo(() => {
        return{
            account,
            role,
            authenticate,
            logout,
            isAuthenticated
        }
    }, [account, role, isAuthenticated])

    return(
        <ClientContext.Provider value={client}>
            {children}
        </ClientContext.Provider>
    )
}

export const useClient = () => {
    const client = useContext(ClientContext)
    return client;
}