import axios from 'axios';
import {Formik} from 'formik';
import { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { url_api } from '../../../config';
import moment from 'moment';

const Schema = Yup.object().shape({
    tgl_mendonor: Yup.string().required('tidak boleh kosong'),
    keterangan: Yup.string().required('tidak boleh kosong'),
    kantong_darah: Yup.string().required('tidak boleh kosong'),
})

const SendRequest = ({fullnamePencari, pencariId, golDarah}) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [message, setMessage] = useState("")

    const _sendRequest = async (data) => {
        try{
            const res = await axios.post(`${url_api}/mendonor/send-request`, {
                fullnamePencari: fullnamePencari,
                pencariId: pencariId,
                tglPermintaan: moment().format("YYYY-MM-DD"),
                tglMendonor: data.tgl_mendonor,
                keterangan: data.keterangan,
                golDarah: golDarah,
                kantong_darah: data.kantong_darah
            }, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            if(res.data.success){
                setMessage(res.data.message)
                setSuccess(res.data.success)
                setLoading(false)
            }
        }catch(err){
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>

            {success === true ?
            <div className='alert alert-success'>{message}. <a href={`/pengajuan-mendonor`}>Lihat Pengajuan Mendonor</a></div> 
            : success === false ?
            <div className='alert alert-danger'>{message}</div> 
            : null}

            <Formik
                initialValues={{
                    tgl_mendonor: '',
                    keterangan: '',
                    kantong_darah: ''
                }}
                validationSchema={Schema}
                onSubmit={(values) => {
                    setTimeout(() => {
                        _sendRequest(values)
                    }, 500)

                    setLoading(true)
                }}
            >

            {({values, errors, touched, handleSubmit, handleChange}) => (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={3}>
                            <div className='mb-1'>
                                <label htmlFor='tgl_mendonor' className='mb-1'>Tanggal Mendonor</label>
                                <input
                                    type='date'
                                    name='tgl_mendonor'
                                    id='tgl_mendonor'
                                    className='form-control'
                                    onChange={handleChange}
                                />
                                {errors.tgl_mendonor && touched.tgl_mendonor ? <span className='text-muted'><font color="red">{errors.tgl_mendonor}</font></span> : null}
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className='mb-1'>
                                <label htmlFor='keterangan' className='mb-1'>Keterangan</label>
                                <input 
                                    type='text'
                                    name='keterangan'
                                    id='keterangan'
                                    className='form-control'
                                    onChange={handleChange}
                                    placeholder='Berikan keterangan ke pendonor'
                                />
                                {errors.keterangan && touched.keterangan ? <span className='text-muted'><font color="red">{errors.keterangan}</font></span> : null}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className='mb-1'>
                                <label htmlFor='kantong_darah' className='mb-1'>Jumlah Kantong</label>
                                <input
                                    type='number'
                                    name='kantong_darah'
                                    id='kantong_darah'
                                    className='form-control'
                                    onChange={handleChange}
                                    placeholder="Jumlah Kantong"
                                />
                                {errors.kantong_darah && touched.kantong_darah ? <span className='text-muted'><font color="red">{errors.kantong_darah}</font></span> : null}
                            </div>
                        </Col>
                        <Col md={2} style={{marginTop: '27px'}}>
                            {loading ?
                            <button className='btn btn-primary w-100' disabled>Loading... <Spinner size='sm' /></button>
                            :
                            <button type='submit' className='btn btn-primary w-100'>Kirim</button>
                            }
                        </Col>
                    </Row>
                </form>
            )}

            </Formik>
        </>
    )
}

export default SendRequest;