import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { MenuAdmin } from "../../components";
import axios from "axios";
import { url_api } from "../../config";
import { useState } from "react";
import { useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheckCircle, faEye } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

const cardStyle = {
    borderRadius: '30px',
    borderColor: 'red',
    padding: '30px'
}

const Layout = ({user}) => {
    const [pendonor, setPendonor] = useState([])
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [keyword, setKeyword] = useState("")
    const [query, setQuery] = useState("")
    const [message, setMessage] = useState("")

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setMessage("");
        setKeyword(query);
    }

    const _fetchData = async () => {
        try{
            const res = await axios.get(`${url_api}/pendonor?search=${keyword}&page=${page}&limit=${limit}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPendonor(res.data.result)
            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchData()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, keyword, limit])

    return(
        <>
            <MenuAdmin user={user}/>

            <Container className="mt-5">
                <Card style={cardStyle}>
                    <h3>Data Pendonor</h3>
                    <Row>
                        <Col md={10}>
                            Semua Pendonor Sukarela yang telah mendaftar
                        </Col>
                        <Col md={2}>
                            <select
                                name="limit"
                                id="limit"
                                className="form-select"
                                onChange={(e) => {
                                    setLimit(e.target.value)
                                }}
                            >
                                <option value="">Limit Data</option>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={150}>150</option>
                                <option value={200}>200</option>
                            </select>
                        </Col>
                    </Row><hr/>

                    <form onSubmit={searchData} style={{marginTop: '2%'}}>
                        <Row>
                            <Col md={4} xs={7}>
                                <input 
                                    type="text"
                                    className="form-control"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    placeholder="Cari Nama Depan Pendonor..."
                                />
                            </Col>
                            <Col md={2} xs={4}>
                                <button type="submit" className="btn btn-outline-danger w-100">Cari</button>
                            </Col>
                        </Row>
                    </form>

                    <Table style={{marginTop: '2%'}} responsive={true} striped bordered>
                        <thead>
                            <tr>
                                <th>Nama Lengkap</th>
                                <th>Email</th>
                                <th>Nomor HP</th>
                                <th>Jenis Kelamin</th>
                                <th>Gol Darah</th>
                                <th>Tgl Regis</th>
                                <th>Opsi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendonor.length > 0 ?
                            pendonor.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.firstname} {val.lastname}</td>
                                        <td>{val.email} {val.verified_email === 'no' ? <a href="#s" style={{color: 'red'}} title="Belum Terverifikasi"><FontAwesomeIcon icon={faTimes} /></a> : <a href="#s" style={{color: 'green'}} title="Terverifikasi"><FontAwesomeIcon icon={faCheckCircle} /></a>}</td>
                                        <td>+62 {val.no_hp} {val.verified_nohp === 'no' ? <a href="#s" style={{color: 'red'}} title="Belum Terverifikasi"><FontAwesomeIcon icon={faTimes} /></a> : <a href="#s" style={{color: 'green'}} title="Terverifikasi"><FontAwesomeIcon icon={faCheckCircle} /></a>}</td>
                                        <td>{val.pendonors[0].jenis_kelamin}</td>
                                        <td>
                                            { val.pendonors[0].gol_darah === 'APLUS' ? 'A+' : val.pendonors[0].gol_darah === 'ABPLUS' ? 'AB+' : val.pendonors[0].gol_darah === 'OPLUS' ? 'O+'
                                            : val.pendonors[0].gol_darah }
                                        </td>
                                        <td>{val.tgl_regis}</td>
                                        <td>
                                            <Link to={`/detail-pendonor/${val.id}`} className="btn btn-success btn-sm"><FontAwesomeIcon icon={faEye} /></Link>&nbsp;
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={8} align="center">Belum ada data</td>
                            </tr>
                            }
                        </tbody>
                    </Table>

                    <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                    <div><font color="red">{message}</font></div>

                    {pendonor.length > 0 ? 
                    <nav key={rows} style={{marginTop: '1%'}}>
                        <ReactPaginate
                            previousLabel={"< Previous"}
                            nextLabel={"Next >"}
                            pageCount={Math.min(10, pages)}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            pageLinkClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            activeLinkClassName={"page-item active"}
                            disabledLinkClassName={"page-item disabled"}
                        />
                    </nav>
                    : null}

                </Card>
            </Container>
        </>
    )
}

export default Layout;