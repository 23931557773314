import Helmet from 'react-helmet'
import Layout from './Layout';
import './styles.css';

const Homepage = () => {
    return(
        <>
            <Helmet>
                <title>Pendonor Sukarela - Mencari Pendonor Sukarela Dengan Mudah</title>
            </Helmet>

            <Layout />
        </>
    )
}

export default Homepage;