import Helmet from 'react-helmet'
import Layout from './Layout';
import { useClient } from '../../components';
import { useEffect, useMemo, useState } from 'react';

const ProfilPencari = () => {
    const client = useClient()
    const [user, setUser] = useState([])
    const [detailUser, setDetailUser] = useState([])

    useEffect(() => {
        client.account.then((val) => {
            setUser(val)
            setDetailUser(val.pencari)
        })
    }, [client])

    const defaultData = useMemo(() => {
        if(!user && !detailUser) return {}

        return {
            user,
            detailUser
        }
    }, [user, detailUser])

    return(
        <>
            <Helmet>
                <title>Profil</title>
            </Helmet>

            <Layout data={defaultData}/>
        </>
    )
}

export default ProfilPencari;