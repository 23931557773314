import { useEffect, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import axios from 'axios';
import { url_api } from '../../config';
import { ToastAlert } from "../../components";

const ModalDelete = ({data, show, onClose = () => {} }) => {
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const dataDefault = useMemo(() => {
        if(!data) return {}

        return {
            idPermintaan: data.idPermintaan,
        }
    }, [data])

    const _deleteData = async (idPermintaan) => {
        try{
            const res = await axios.delete(`${url_api}/mendonor/permintaan/${idPermintaan}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            if(res.data.success){
                setMessage(res.data.message)
                setSuccess(res.data.success)
                setLoading(false)
                setShowToast(true)

                setTimeout(() => {
                    window.location.href = "/permintaan-mendonor";
                }, 1500)
            }
        }catch(err){
            console.log(err)
        }
    }


    return(
        <>
            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Modal show={show} onHide={onClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Hapus Permintaan Mendonor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <h3 align="center">Yakin data permintaan ini ingin dihapus?</h3>
                    <p align="center">Data ini tidak akan hilang secara permanent</p>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary btn-sm" onClick={onClose}>Tidak</button>

                    {loading ?
                    <button className="btn btn-danger btn-sm" disabled><Spinner size="sm"/></button>
                    :
                    <button className="btn btn-danger btn-sm" onClick={() => {
                        setTimeout(() => {
                            _deleteData(dataDefault.idPermintaan)
                        }, 1000)

                        setLoading(true)
                    }}>Ya</button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDelete;