import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'

const Header = () => {
    const history = useHistory()

    return(
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <div style={{color: '#ff2323'}}>Pendonor Sukarela</div>
                </Navbar.Brand>

                <Navbar.Toggle />

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="#" onClick={() => history.push('/')}>Beranda</Nav.Link>
                        <Nav.Link href="#about">Tentang</Nav.Link>
                        <Nav.Link href="/register/pendonor">Mau Jadi Pendonor?</Nav.Link>
                        <Nav.Link href="/register/pencari">Mau Cari Pendonor?</Nav.Link>

                        {sessionStorage.getItem('token') !== null ?
                        <Nav.Link href={`/${sessionStorage.getItem('role')}`}>Dashboard</Nav.Link>
                        :
                        <NavDropdown title="Masuk" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/login-pencari">Pencari</NavDropdown.Item>
                            <NavDropdown.Item href="/login-pendonor">Pendonor</NavDropdown.Item>
                            <NavDropdown.Item href="/login-pmi">PMI atau Bank Darah</NavDropdown.Item>
                        </NavDropdown>
                        }

                        {/* <Nav.Link href="#" onClick={() => history.push('/login')}>Masuk</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;