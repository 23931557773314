import {Formik} from 'formik'
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import {url_api} from '../../config'

const ChangeProfil = ({user}) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [message, setMessage] = useState('')

    const _saveProfil = async (data) => {
        try{
            const res = await axios.post(`${url_api}/profil/change-admin/${user.id}`, {
                firstname: data.firstname,
                lastname: data.lastname,
            }, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })
    
            setSuccess(res.data.success)
            setMessage(res.data.message)
            setLoading(false)
        }catch(err){
            setLoading(false)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    return(
        <>
            <Formik
                initialValues={{
                    firstname: user.firstname,
                    lastname: user.lastname,
                }}
                onSubmit={(values) => {
                    setTimeout(() => {
                        _saveProfil(values)
                    }, 1000)

                    setLoading(true)
                }}
            >

            {({values, errors, touched, handleSubmit, handleChange}) => (
              
                <form onSubmit={handleSubmit}>

                    {success === true ?
                    <div className='alert alert-success'>{message}</div>
                    : success === false ?
                    <div className='alert alert-danger'>{message}</div>
                    :
                    null
                    }

                    <div className='mb-3'>
                        <label htmlFor='firstname'><b>Nama Depan</b></label>
                        <input 
                            type="text" 
                            className='form-control mt-2' 
                            id='firstname' 
                            placeholder='Nama Depan'
                            onChange={handleChange}
                            value={values.firstname} 
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='lastname'><b>Nama Belakang</b></label>
                        <input 
                            type="text" 
                            className='form-control mt-2' 
                            id='lastname' 
                            placeholder='Nama Belakang'
                            onChange={handleChange}
                            value={values.lastname} 
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='email'><b>Email</b></label>
                        <input 
                            type="text" 
                            className='form-control mt-2' 
                            id="email"
                            value={user.email}
                            disabled
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='no_hp'><b>Nomor HP</b></label>
                        <div className="input-group mt-2">
                            <span className="input-group-text" id="basic-addon1">+62</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                id='no_hp' 
                                placeholder="Nomor HP"
                                value={user.no_hp} 
                                disabled
                            />
                        </div>
                    </div><hr/>
                    {loading ?
                    <button className='btn btn-outline-danger disabled'>Loading... <Spinner size='sm' /></button>
                    :
                    <button type="submit" className='btn btn-outline-danger'>Simpan Profil</button>
                    }
                    
                </form>

            )}

            </Formik>
        </>
    )
}

export default ChangeProfil;