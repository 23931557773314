import { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import pictureOne from './picture-1.jpg';
import pictureTwo from './picture-2.jpg';
import pictureThree from './picture-3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import {Formik} from 'formik';
import { ToastAlert } from "../../components";
import { url_api } from '../../config';
import { useHistory } from 'react-router-dom';
import axios from "axios";

// Styles
const cardRegis = {
    borderRadius: '20px',
    borderColor: '#ff2323',
    padding: '30px'
}

const Layout = () => {
    const [bgStyle, setBgStyle] = useState({
        height: '100%',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    })
    const history = useHistory()
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const _handleLogin = async (data) => {
        try{
            const res = await axios.post(`${url_api}/auth/login`, {
                no_hp: data.no_hp,
                password: data.password,
                role: 'admin'
            })
    
            if(res.data.success){
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('role', res.data.role)
    
                history.push(`/${res.data.role}`) // redirect
            }
        }catch(err){
            setShowToast(true)
            setMessage(err.response.data.message)
            setSuccess(err.response.data.success)
        }
    }

    useEffect(() => {
        const pictureArray = [pictureOne, pictureTwo, pictureThree];
        const randomIndex = Math.floor(Math.random() * pictureArray.length);
        const selectedPicture = pictureArray[randomIndex];

        setBgStyle({
            backgroundImage: `url(${selectedPicture})`,
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        })
    }, [])

    return(
        <div style={bgStyle} className="bg">
            <Container className="pt-5 pb-5">

                <ToastAlert 
                    body={message}
                    variant={success ? 'success' : 'danger'}
                    onShow={showToast}
                    onClose={() => setShowToast(false)}
                    success={success}
                    containerPosition="fixed"
                    position="top-end"
                />

                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <Card style={cardRegis}>
                            <h3 align="center" style={{color: '#ff2323'}}>Pendonor Sukarela</h3><hr/>
                            <h5>Login Administrator</h5>
                            <p>Silahkan masuk ke dashboard admin untuk mengelola sistem ini</p>

                            <Formik
                                initialValues={{
                                    no_hp: '',
                                    password: ''
                                }}
                                onSubmit={(values) => {
                                    setTimeout(() => {
                                        _handleLogin(values)
                                        setLoading(false)
                                    }, 1000)

                                    setLoading(true)
                                }}
                            >

                                {({values, errors, touched, handleSubmit, handleChange}) => (
                                    
                                    <form onSubmit={handleSubmit}>

                                        <div className='mb-2'>
                                            <label htmlFor='no_hp' className='mb-2'><b>Nomor HP</b></label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addon1">+62</span>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id='no_hp' 
                                                    name='no_hp' 
                                                    placeholder="Nomor HP"
                                                    onChange={handleChange} 
                                                    required 
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <label htmlFor='password' className='mb-2'><b>Password</b></label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faLock} /></span>
                                                <input 
                                                    type="password" 
                                                    className="form-control" 
                                                    id='password' 
                                                    name='password' 
                                                    placeholder="Password"
                                                    onChange={handleChange} 
                                                    required 
                                                />
                                            </div>
                                        </div>

                                        <Row className='mt-4'>
                                            <Col md={5}>
                                                {loading ?
                                                <button className='btn btn-outline-danger w-100' disabled>Loading...</button>
                                                :
                                                <button type='submit' className='btn btn-outline-danger w-100'>Login</button>
                                                }
                                            </Col>
                                        </Row>
                                    </form>

                                )}

                            </Formik><hr/>

                            <div align="center">Tidak punya akses? <a href="/" style={{textDecoration: 'none'}}>Kembali</a></div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Layout;