export const url_api = "https://api-pendonorsukarela.saskardigital.com/api/v1";
export const url_static = "https://api-pendonorsukarela.saskardigital.com/";

// export const url_api = "http://localhost:8080/api/v1";
// export const url_static = "http://localhost:8080";

export const openInNewTab = url => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
};

export const getCurrentDate = () => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;

    return currentDate;
}