import axios from "axios";
import { useMemo } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { url_api } from "../../config";
import { useState } from "react";
import { ToastAlert } from "../../components";

const ModalDelete = ({data, show, onClose = () => {}}) => {
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const dataDefault = useMemo(() => {
        if(!data) return {}

        return {
            idPengajuan: data.idPengajuan,
        }
    }, [data])

    const _deleteData = async (idPengajuan) => {
        try{
            const res = await axios.delete(`${url_api}/mendonor/pengajuan/${idPengajuan}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            if(res.data.success){
                setMessage(res.data.message)
                setSuccess(res.data.success)
                setLoading(false)
                setShowToast(true)

                setTimeout(() => {
                    window.location.href = "/data-pengajuan";
                }, 1500)
            }
        }catch(err){
            console.log(err)
        }
    }

    return(
        <>
            <ToastAlert 
                body={message}
                variant={success ? 'success' : 'danger'}
                onShow={showToast}
                onClose={() => setShowToast(false)}
                success={success}
                containerPosition="fixed"
                position="top-end"
            />

            <Modal show={show} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 align="center">Apakah yakin ingin menghapus data pengajuan ?</h5>
                    <p align="center">Jika dihapus maka data ini akan hilang secara permanent</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary btn-sm" onClick={onClose}>Tidak</button>

                    {loading ?
                    <button className="btn btn-danger btn-sm" disabled><Spinner size="sm"/></button>
                    :
                    <button className="btn btn-danger btn-sm" onClick={() => {
                        setTimeout(() => {
                            _deleteData(dataDefault.idPengajuan)
                        }, 1000)

                        setLoading(true)
                    }}>Ya</button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDelete;