import { Card, Col, Container, Row } from "react-bootstrap";
import { MenuPencari } from "../../components";
import CardPendonor from "./components/CardPendonor";
import { useState, useEffect } from "react";
import axios from "axios";
import { url_api } from "../../config";
import ReactPaginate from "react-paginate";
import SendRequest from "./components/SendRequest";

const Layout = ({data}) => {
    const [limit, setLimit] = useState(20)
    const [pendonor, setPendonor] = useState([])
    const [page, setPage] = useState(0)
    const [pages, setPages] = useState(0)
    const [rows, setRows] = useState(0)
    const [keyword, setKeyword] = useState("")
    const [query, setQuery] = useState("")
    const [message, setMessage] = useState("")

    const changePage = ({selected}) => {
        setPage(selected)

        if(selected === 9){
            setMessage("Data tidak ditemukan")
        }else{
            setMessage("")
        }
    }

    const _fetchData = async () => {
        try{
            const res = await axios.get(`${url_api}/pendonor/get-all?search=${keyword}&page=${page}&limit=${limit}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPendonor(res.data.result)
            setPage(res.data.page)
            setPages(res.data.totalPage)
            setRows(res.data.totalRows)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchData()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, keyword, limit])

    return(
        <>
            <MenuPencari data={data}/>

            <Container className="mt-5">
                <h4>Hai, {data.user.firstname} {data.user.lastname}</h4>
                <p>Dibawah ini adalah data pendonor yang siap untuk mendonorkan darah mereka</p><hr/>

                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <select
                                name="gol_darah"
                                id="gol_darah"
                                className="form-select"
                                onChange={(e) => {
                                    setQuery(e.target.value)
                                }}
                                required
                            >
                                <option value="">Pilih Golongan Darah</option>
                                <option value="A">A</option>
                                <option value="APLUS">A+</option>
                                <option value="AB">AB</option>
                                <option value="ABPLUS">AB+</option>
                                <option value="O">O</option>
                                <option value="OPLUS">O+</option>
                            </select>
                        </div>
                    </Col>
                    <Col md={6}>
                        <button className="btn btn-primary" onClick={() => {
                            setPage(0);
                            setMessage("");
                            setKeyword(query);
                        }}>Cari Sekarang</button>
                    <br/><br/></Col>
                    <Col md={2}>
                        <select
                            name="limit"
                            id="limit"
                            className="form-select"
                            onChange={(e) => {
                                setLimit(e.target.value)
                            }}
                        >
                            <option value="">Limit Data</option>
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                            <option value={200}>200</option>
                        </select>
                    </Col>
                </Row>

                {/* Send Request Mendonor */}
                {keyword !== "" && pendonor.length !== 0 ?
                <Card
                    style={{
                        borderRadius: '20px',
                        borderColor: 'green',
                        padding: '20px',
                        marginBottom: '20px'
                    }}
                >
                    <h4 className="mb-4">Kirim Permintaan Sekarang ke Golongan Darah <b>{ keyword === 'APLUS' ? 'A+' : keyword === 'ABPLUS' ? 'AB+' : keyword === 'OPLUS' ? 'O+' : keyword }</b></h4>

                    <SendRequest 
                        fullnamePencari={`${data.user.firstname} ${data.user.lastname}`}
                        pencariId={data.detailUser.id}
                        golDarah={keyword}
                    />
                </Card>
                :
                <div className="alert alert-info">{pendonor.length > 0 ? "Untuk mengirim permintaan mendonor silahkan memilih golongan darah terlebih dahulu" : "Tidak bisa mengirim permintaan karena gol darah yang dipilih tidak ada pendonor"}</div>
                }


                {/* Data Pendonor */}
                {pendonor.length > 0 ?
                <Row>
                    {pendonor.map((val, key) => {
                        return (
                            <Col md={4} key={key}>
                                <CardPendonor 
                                    data={val}
                                />
                            <br/></Col>
                        )
                    })}
                </Row>
                :
                <h5>Belum ada pendonor...</h5>
                }<hr/>
                

                <p>Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}</p>                            
                <div><font color="red">{message}</font></div>

                {pendonor.length > 0 ? 
                <nav key={rows} style={{marginTop: '1%'}}>
                    <ReactPaginate
                        previousLabel={"< Previous"}
                        nextLabel={"Next >"}
                        pageCount={Math.min(10, pages)}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        activeLinkClassName={"page-item active"}
                        disabledLinkClassName={"page-item disabled"}
                    />
                </nav>
                : null}
            </Container><br/>
        </>
    )
}

export default Layout;