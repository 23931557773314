import { Col, Container, Row } from "react-bootstrap";
import { MenuPendonor } from "../../components";
import { useEffect, useState } from "react";
import axios from "axios";
import { url_api } from "../../config";
import moment from "moment";
import CardPencari from "./CardPencari";

const Layout = ({data}) => {
    const [permintaan, setPermintaan] = useState([])

    const _fetchRequest = async () => {
        const tglSekarang = moment().format("YYYY-MM-DD")

        try{
            const res = await axios.get(`${url_api}/mendonor/get-request-goldar/${data.detailUser.gol_darah}/${tglSekarang}`, {
                headers: {"Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })

            setPermintaan(res.data.result)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        _fetchRequest()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return(
        <>
            <MenuPendonor data={data}/>

            <Container className="mt-5">

                <h4>Hai, {data.user.firstname} {data.user.lastname}</h4>
                <p>Dibawah ini adalah data permintaan mendonor untuk hari ini</p><hr/>

                <h2>Permintaan Mendonor...</h2>

                {permintaan === undefined ? null
                :
                
                permintaan.length > 0 ? // jika sudah ada permintaan

                <Row className="mt-4">
                    {permintaan.map((val, key) => {
                        return (
                            <Col md={4} key={key}>
                                <CardPencari 
                                    dataUser={data}
                                    dataPencari={val}
                                />
                            </Col>
                        )
                    })}
                </Row>

                : // jika belum ada permintaan
                
                <h5>Belum ada permintaan untuk hari ini. <a href="/pendonor">Silahkan Refresh</a></h5>

                }
            </Container><br/>
        </>
    )
}

export default Layout;